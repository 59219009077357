/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import { getMainServerEndpoint } from "./endpoints";
import { apiStage, Environment } from "./constants";
import axios from "axios";
import { isEmpty } from "./objectTools";
import { handleError, objectDebug } from "./exception-handling";

export class statusResponse {
    constructor(
        accounts = [],
        requires_reconnect = false,
        target_disconnect_item = "",
        companies = [],
        google_auth_status = false,
        user_sheets = {},
        has_training_job = false,
        link_token = "",
        is_eligible = false,
        filtered_accounts = [],
        sheet_link = "",
        step_number = 0,
        has_connections = false,
        max_fetch_attempts = 3,
        error = false,
        error_code = 0,
        error_console_msg = "",
        error_display_msg = ""
    ) {
        this.accounts = accounts;
        this.requires_reconnect = requires_reconnect;
        this.target_disconnect_item = target_disconnect_item;
        this.companies = companies;
        this.google_auth_status = google_auth_status;
        this.user_sheets = user_sheets;
        this.has_training_job = has_training_job;
        this.link_token = link_token;
        this.is_eligible = is_eligible;
        this.filtered_accounts = filtered_accounts;
        this.sheet_link = sheet_link;
        this.step_number = step_number;
        this.has_connections = has_connections;
        this.max_fetch_attempts = max_fetch_attempts;
        this.error = error;
        this.error_code = error_code;
        this.error_console_msg = error_console_msg;
        this.error_display_msg = error_display_msg;
    };
}

export async function statusCall (session = {}, source = "", element = "") {
    try {
        const statusEndpoint = `${getMainServerEndpoint(Environment, apiStage)}/simplybooks/${session.userEmail}/status`;
        const statusConfig = {headers: {"Authorization": session.idToken}};
        const company_scope = window.sessionStorage.getItem("company_scope");
        console.debug(`statusCall() company_scope: ${company_scope}`)
        let res = await axios.get(statusEndpoint, statusConfig);
        console.debug(`statusCall().userStatusRequest Code: ${res.status}`)
        switch (res.status) {
            case (200):
                const statusData = res.data;
                // console.debug(`statusCall().userStatusRequest Data: ${JSON.stringify(statusData.data)}`)
                const plaid_link_token = statusData.data['plaid_link']['link_token'];
                const is_eligible = statusData.data['user']['is_eligible'];
                const all_accounts = statusData.data['user']['accounts'];
                const requires_reconnect = statusData.data['user']['requires_plaid_reconnect'];
                const target_disconnect_item = statusData.data['user']['target_disconnected_item'];
                const company_options = statusData.data['user']['companies'];
                const google_auth_status = statusData.data['user']['google_auth_status'];
                const has_training_job = statusData.data['user']['has_training_job'];
                const user_sheets_dict = statusData.data['user_sheets'];

                let user_sheet_link = "";
                let wizard_step_number = 0;
                if (google_auth_status) {
                    wizard_step_number = isEmpty(company_scope) ? 2 : 3;
                    user_sheet_link = isEmpty(company_scope) ? "" : user_sheets_dict[company_scope];
                }

                const has_connections = !!all_accounts.length;

                let filtered_accounts = (has_connections && !isEmpty(company_scope)) ? all_accounts.filter(a => a['company'] === company_scope) : [];
                // if (has_connections) {
                    // filteredAccounts = all_accounts.filter(a => a['company'] === company_scope);
                    // console.debug(`****** DEBUG ******: ${JSON.stringify(filteredAccounts)}`)
                    // if (filteredAccounts.length > 0 && filteredAccounts[0] === undefined) {
                    //     filteredAccounts = [];
                    // }
                // }
                console.debug(`****** DEBUG ******: ${JSON.stringify(filtered_accounts)}`)

                return new statusResponse(
                    all_accounts,
                    requires_reconnect,
                    target_disconnect_item,
                    company_options,
                    google_auth_status,
                    user_sheets_dict,
                    has_training_job,
                    plaid_link_token,
                    is_eligible,
                    filtered_accounts,
                    user_sheet_link,
                    wizard_step_number,
                    has_connections,
                    3,
                    false,
                    0,
                    "",
                    ""
                );

            default:
                objectDebug('Failed statusResponse', res.data);
                return new statusResponse(
                    [],
                    false,
                    "",
                    [],
                    false,
                    {},
                    false,
                    "",
                    false,
                    [],
                    "",
                    0,
                    false,
                    3,
                    true,
                    1,
                    `req_id: ${res.data['req_id']}; ${res.data['msg']}`,
                    "A server error occurred. Please refresh the page to continue.\n\nIf the problem persists, please reach out to our support team."
                );
        }

    } catch (error) {
        handleError(
            `[${source}][${element}] statusCall()`,
            error,
            false,
            '',
            `
                 OverArching Exception in statusCall ==> ${error?.message}`
        );
        return new statusResponse(
            [],
            false,
            "",
            [],
            false,
            {},
            false,
            "",
            false,
            [],
            "",
            0,
            false,
            3,
            true,
            2,
            `OverArching Exception`,
            "A server error occurred. Please refresh the page to continue.\n\nIf the problem persists, please reach out to our support team."
        );
    }
}