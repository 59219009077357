/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React from "react";
import './page.scss';
import Footer from "../components/footer/footer";
import { Container, Row, Col } from 'reactstrap';

const About = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    return (
        <div className={'page-splash'}>
            <Container fluid={true} style={{cursor: 'default'}}>
                <Row xs={1} className={'row-1'}>
                    <Col>
                        <div className={'splash-inner'}>
                            <header>
                                <h2><strong>About us</strong></h2>
                            </header>
                            <div className={'page-body'}>
                                <div className={'about-body'}>
                                    <p className={'top-paragraph'}>
                                        As a software developer running a small business myself, it always felt like it was unnecessary
                                        overkill to pay $30 or $40 per month just to have something that my business and personal
                                        transactions flowed into so that I can pay someone <i>(a CPA)</i> to tell me how much money I needed to pay someone
                                        else <i>(the IRS)</i>. Not to mention, other solutions didn't do anything to make it any less of a
                                        do-list item that still had to be done.
                                    </p>
                                    <p className={'paragraph'}>
                                        Naturally, I don't have a lot of
                                        other components of small business to deal with, like invoicing and inventory to keep track of
                                        and things of that nature; and I know that an Excel or Google Sheets based approach isn't for
                                        everybody or every kind of business...but a quick Google Search will show that it can be (<i>and still is</i>) a powerful and viable
                                        alternative to overpriced subscriptions with tons of useless features for 10's of thousands
                                        of businesses across the U.S.
                                    </p>
                                    <p className={'paragraph'}>
                                        This software is what I originally wrote to automate my own bookeeping, and is still what I
                                        personally use to keep my own books in order on an annual basis. At first, you'll be naming
                                        your own transaction categories <i>(building your chart of accounts)</i> just like with any
                                        bookkeeping software, but after a little effort you'll see that SimplyBooks has your back and
                                        is already categorizing for you. At the end of the process, you'll only have to verify that
                                        the algorithm is categorizing correctly, <strong>reducing this entire process into one of
                                        management rather than manual work</strong>.
                                    </p>
                                    <p className={'paragraph'}>
                                        With a simple user interface and a powerful mixture of modern machine learning and integrations under the hood,
                                        my hope is that SimplyBooks can automate your bookkeeping process and free up your time the
                                        same way that it did mine, and I look forward to being just one small step on your path to success.
                                    </p>
                                    <p className={'paragraph'}>
                                        ~ Blake Donahoo, Founder \ Developer
                                    </p>
                                    <div >
                                        <p>Feel free to connect with me personally on <a href={'https://www.linkedin.com/in/bamfdonahoo/'}>LinkedIn</a>!</p>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );


};

export default About;
