/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React, { useState } from "react";
import axios from "axios";
import './settings.scss';
import { getUserSession } from "../../utils/session";
import { getMainServerEndpoint } from "../../utils/endpoints";
import { getPageLocation } from "../../utils/getPageLocation";
import { handleError, objectDebug } from "../../utils/exception-handling";
import { Environment, apiStage } from "../../utils/constants";

const UpdatePaymentMethod = ({ stripeData }) => {
    const [checkoutSession, setCheckoutSession] = useState({});

    const onClickUpdatePaymentMethod = (event) => {
        event.preventDefault();
        try {
            getUserSession().then(session => {
                const updatePaymentMethodEndpoint = `${getMainServerEndpoint(Environment, apiStage)}/simplybooks/${session.userEmail}/update_payment_method`;
                const updatePayload = {
                    'user_id': stripeData['customer'],
                    'subscription_id': stripeData['subscription']['id'],
                    'success_url': `${getPageLocation(Environment, apiStage, 'settings')}`,
                    'cancel_url': `${getPageLocation(Environment, apiStage, 'settings')}`
                };
                const config = {headers: {"Authorization": session.idToken, "Content-Type": "application/json"}};
                axios.post(updatePaymentMethodEndpoint, updatePayload, config).then(res => {
                    objectDebug('updatePaymentMethodEndpoint', res.data);
                    if (res.status !== 200) throw new Error(`Account Status Endpoint returned a ${res.status} code.`);
                    setCheckoutSession(res.data['data']['session']);
                    const checkoutUrl = res.data['data']['session']['url'];
                    window.location = `${checkoutUrl}`;

                }).catch(error => {
                    handleError(
                        'components.membership.edit-membership-inputs.UpdatePaymentMethod.onClickUpdatePaymentMethod.updatePaymentMethodEndpoint',
                        error,
                        false,
                        '',
                        'Caught error from onClickUpdatePaymentMethod.updatePaymentMethodEndpoint',
                        null
                    );
                })

            }).catch(error => {
                handleError(
                    'components.membership.edit-membership-inputs.UpdatePaymentMethod.onClickUpdatePaymentMethod.getUserSession',
                    error,
                    false,
                    '',
                    'Caught error from onClickUpdatePaymentMethod.getUserSession',
                    null
                );
            })

        } catch (error) {
            handleError(
                'components.membership.edit-membership-inputs.UpdatePaymentMethod.onClickUpdatePaymentMethod',
                error,
                false,
                '',
                'Caught error from onClickUpdatePaymentMethod',
                null
            );
        }
    };

    return (
        <div className={'update-payment-method-button'}>
            <button
                onMouseOver={e => (e.currentTarget.style.backgroundColor = '#2ade64')}
                onMouseOut={e => (e.currentTarget.style.backgroundColor = '#1ab04a')}
                onClick={e => onClickUpdatePaymentMethod(e)}
            >
                Update Payment Method
            </button>
        </div>
    );
};

export default UpdatePaymentMethod;
