/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React, { useState, useEffect } from "react";
import './App.scss';
import '@aws-amplify/ui-react/styles.css';
import { Hub } from "aws-amplify/utils";
import { getPageLocation } from "./utils/getPageLocation";
import { isLoggedIn } from "./utils/session";
import { Environment, apiStage } from "./utils/constants";
import { objectDebug } from "./utils/exception-handling";
import NavBar from "./components/navigation/nav-bar";
import SignInModal from "./components/sign-in-modal/sign-in-modal";


function App(props) {
  const [isAuthenticated, setAuthentication] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  window.sessionStorage.setItem("referral_id", "");

  const authListener = (data) => {
    objectDebug('navbar.authListener', data)
    switch(data?.payload?.event) {
      case 'signedIn':
        console.info(`user signed in: ${data?.payload?.data?.signInDetails?.loginId.toUpperCase()}`);
        window.sessionStorage.setItem('user_email', `${data?.payload?.data?.signInDetails?.loginId.toUpperCase()}`);
        window.sessionStorage.setItem("company_scope", "");
        window.sessionStorage.setItem('link_token', "");
        setAuthentication(true);
        setUserEmail(`${data?.payload?.data?.signInDetails?.loginId.toUpperCase()}`);
        // navigate('connect');
        window.location = `${getPageLocation(Environment, apiStage, 'bank-connections')}`;
        // window.location = `${getPageLocation(Environment, apiStage, '/')}`;
        break;
      case 'tokenRefresh':
        console.info(`user token refreshed: ${data?.payload?.data?.signInDetails?.loginId.toUpperCase()}`);
        setAuthentication(true);
        setUserEmail(`${data?.payload?.data?.signInDetails?.loginId.toUpperCase()}`);
        break
      case 'signedUp':
        console.info(`user signed up: ${data?.payload?.data?.signInDetails?.loginId.toUpperCase()}`);
        setAuthentication(true);
        setUserEmail(`${data?.payload?.data?.signInDetails?.loginId.toUpperCase()}`);
        // navigate('/');
        window.location = `${getPageLocation(Environment, apiStage, '/')}`;
        break;
      case 'signedOut':
        console.info('user signed out');
        setAuthentication(false);
        setUserEmail('');
        window.localStorage.clear();
        window.sessionStorage.clear();
        // navigate('/');
        window.location = `${getPageLocation(Environment, apiStage, '/')}`;
        break;
      case 'signIn_failure':
        console.info('user sign in failed');
        break;
      case 'configured':
        console.info('the Auth module is configured');
        break;
      default:
        console.debug(`NavBar.authListener: ${data?.payload?.event}`)
        console.error('Something went wrong, look at data object');
        console.debug(data)
    }
  };
  Hub.listen('auth', authListener);

  useEffect(() => {
    if (!isAuthenticated) {
      try {
        isLoggedIn().then(isSignedIn => {
          if (isSignedIn) {
            console.debug(`App.authChecker.isLoggedIn: Updating user authentication status.`)
            setAuthentication(true)
          } else {
            console.debug(`App.authChecker.isLoggedIn: User is not authenticated.`)
          }
        }).catch(error => {

          console.debug(`App.useEffect.isLoggedIn Error`)
          console.error(error)
        })
      } catch (error) {
        console.debug(`App.useEffect Error`)
        console.error(error)
      }
    }

  }, [isAuthenticated])

  const handleSignInModal = (e) => {
    e.preventDefault();
    console.log('---> Sign In')
    setShowSignIn(!showSignIn);
  };

  const toggleSignInAndNavigate = (e, link = "") => {
    e.preventDefault();
    setShowSignIn(!showSignIn);
    window.location = link;
  };

  return (
      <div className={'App'}>
        <header>
          <NavBar
            isAuthenticated={isAuthenticated}
            userEmail={userEmail}
            handleSignInModal={handleSignInModal}
          />
        </header>
        {isAuthenticated ? <div></div> : <SignInModal
            handleSignInModal={handleSignInModal}
            showSignIn={showSignIn}
            toggleSignInAndNavigate={toggleSignInAndNavigate}
        />}
      </div>
  );
}

export default App;
