/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React from "react";


const SignOutGIF = () => {
    return (
        <div className={"Sign-Out"}>
            <h1 style={{textAlign: 'center', paddingTop: '10px'}}>Signing you out ...</h1>
        </div>
    );
};

export default SignOutGIF;