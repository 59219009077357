/*Copyright 2024, Blake Donahoo, LLC, All rights reserved.*/
import React from "react";
import './connection-table.scss';
import { dotGet } from "../../utils/objectTools";
import trashIcon from '../../assets/trash_icon_super_mini.png';
import redTrashIcon from '../../assets/trash_icon_super_mini_red_r.png';
import statusDotRed from '../../assets/statusDot_red.png';
import statusDotGreen from '../../assets/statusDot_green.png';


const ConnectionTable = ({ onClickRemove, accounts, companyScope }) => {

    const filteredAccounts = accounts.filter(a => a['company'] === companyScope);

    const RemovalButton = (d) => {
        return (
            <img
                src={trashIcon}
                alt={"trashIcon"}
                className={'trashIcon'}
                onClick={event => onClickRemove(event, d.accountObjIdx)}
                onMouseOver={e => (e.currentTarget.src = redTrashIcon)}
                onMouseOut={e => (e.currentTarget.src = trashIcon)}
            />
        );
    };

    const AccountStatusDot = (s) => {
        let accountStatusDot = <img
            src={statusDotRed}
            alt={'disconnected'}
            className={'account-status-dot'}
        />;
        if (s.status) {
            accountStatusDot = <img
                src={statusDotGreen}
                alt={'connected'}
                className={'account-status-dot'}
            />;
        }
        return accountStatusDot;
    };

    const tableRows = filteredAccounts.map((accountObj, accountObjIdx) => {
        if (accountObjIdx % 2 === 0){
            return (
                <tr key={accountObjIdx}>
                    <td bgcolor={'#eee'}>{dotGet(accountObj['display_name'])}</td>
                    <td bgcolor={'#eee'}>{dotGet(accountObj['ins_name'])}</td>
                    <td bgcolor={'#eee'}>{dotGet(accountObj['subtype'])}</td>
                    <td bgcolor={'#eee'}>
                        <AccountStatusDot status={dotGet(accountObj['account_status'])} />
                    </td>
                    <td bgcolor={'#eee'}>
                        <RemovalButton accountObjIdx={accountObjIdx} />
                    </td>
                </tr>
            );  // https://react.dev/reference/react-dom/components/input
            // https://javascript.plainenglish.io/react-onchange-with-input-field-button-854f0035b94a

        } else {
            return (
                <tr key={accountObjIdx}>
                    <td>{dotGet(accountObj['display_name'])}</td>
                    <td>{dotGet(accountObj['ins_name'])}</td>
                    <td>{dotGet(accountObj['subtype'])}</td>
                    <td>
                        <AccountStatusDot status={dotGet(accountObj['account_status'])} />
                    </td>
                    <td>
                        <RemovalButton accountObjIdx={accountObjIdx} />
                    </td>
                </tr>
            );
        }
    });

    if (tableRows.length > 0) {
        return (
            <div className={'connection-table'}>
                <h3>Current account connections</h3>
                <table
                    cellSpacing={5}
                    cellPadding={5}
                    border={0}
                    align={'left'}
                    bgcolor={'#ffffff'}
                    className={'connection-table-contents'}
                >
                    <thead>
                    <tr>
                        <th>Account</th>
                        <th>Bank</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Remove</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableRows}
                    </tbody>
                </table>


            </div>
        );

    } else {
        return (
            <div className={'connection-table'}>
                <h3>No accounts currently connected</h3>
            </div>
        );

    }
};

export default ConnectionTable;