/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React, { useState } from "react";
import './page.scss';
import { resetPassword, confirmResetPassword } from "@aws-amplify/auth";
import { getAndDebug, handleError, objectDebug } from "../utils/exception-handling";
import { getPageLocation } from "../utils/getPageLocation";
import { apiStage, Environment } from "../utils/constants";
import { Col, Container, Row } from "reactstrap";
import LoadingGIF from "../components/GIFS/loading";
import Footer from "../components/footer/footer";

const ResetPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [confirmationDest, setConfirmationDest] = useState('*****');
    const [resetConfirmationRequired, setResetConfirmationRequired] = useState(false);
    const [resetUsername, setResetUsername] = useState('');
    window.scrollTo({ top: 0, behavior: "smooth" });
    async function handleResetPassword( username ) {
        try {
            return await resetPassword({ username });
        } catch (error) {
            handleError(
                'pages.reset-password.handleResetPassword',
                error,
                false,
                '',
                'Caught error in handleResetPassword'
            );
        }
    }

    const onClickResetPassword = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const username = getAndDebug(document.getElementById('reset-username').value, 'pages.reset-password.onClickResetPassword [username]');
        setResetUsername(username);
        handleResetPassword(username).then(output => {
            objectDebug('handleResetPassword [output]', output);
            const { nextStep } = output;
            switch (nextStep.resetPasswordStep) {
                case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
                    const codeDeliveryDetails = nextStep.codeDeliveryDetails;
                    objectDebug('handleResetPassword [codeDeliveryDetails]', codeDeliveryDetails);
                    console.log(`Confirmation code was sent to ${codeDeliveryDetails.destination}`);
                    setConfirmationDest(`${codeDeliveryDetails.destination}`);
                    setResetConfirmationRequired(true);
                    setIsLoading(false);
                    // Collect the confirmation code from the user and pass to confirmResetPassword.
                    break;
                case 'DONE':
                    console.log('Successfully reset password.');
                    setIsLoading(false);
                    break;

            }
        }).catch(error => {
            handleError(
                'pages.reset-password.onClickResetPassword.handleResetPassword',
                error,
                true,
                'An error occurred while attempting to reset your password',
                'Caught error in onClickResetPassword.handleResetPassword'
            );
            setIsLoading(false);
        })

    };

    async function handleConfirmResetPassword({ username, confirmationCode, newPassword }) {
        try {
            await confirmResetPassword({ username, confirmationCode, newPassword });
        } catch (error) {
            handleError(
                'pages.reset-password.handleConfirmResetPassword',
                error,
                true,
                'An error occurred while attempting to confirm your password reset',
                'Caught error in handleConfirmResetPassword'
            );
        }
    }

    const onClickConfirmReset = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const confirmationCode = getAndDebug(document.getElementById('reset-code').value, 'pages.reset-password.onClickConfirmReset [confirmationCode]');
        const newPassword = getAndDebug(document.getElementById('new-password').value, 'pages.reset-password.onClickConfirmReset [newPassword]');
        const newPasswordRetype = getAndDebug(document.getElementById('retype-password').value, 'pages.reset-password.onClickConfirmReset [newPasswordRetype]');
        const username = resetUsername;
        if (newPassword !== newPasswordRetype) {
            console.debug(`New input passwords do not match.`)
            window.alert(`Your new password inputs do not match.\n\nPlease try again`);
            setIsLoading(false);
        } else {
            handleConfirmResetPassword({ username, confirmationCode, newPassword }).then(res => {
                console.debug(`Password reset complete!`)
                window.alert(`Your password has been reset!\n\nPlease sign in to continue.`);
                window.location = `${getPageLocation(Environment, apiStage, '/')}`;
                setIsLoading(false);
            }).catch(error => {
                handleError(
                    'pages.reset-password.onClickConfirmReset.handleConfirmResetPassword',
                    error,
                    false,
                    '',
                    'Caught error in onClickConfirmReset.handleConfirmResetPassword'
                );
                setIsLoading(false);
            });
        }
    };

    const ResetConfirmationView = () => {
        return (
            <div className={'reset-view'}>
                <div className={'header-div'}>
                    <h2>Enter the code sent to {confirmationDest} to complete your password reset.</h2>
                </div>
                <div>
                    <form onSubmit={e => onClickConfirmReset(e)}>
                        <span>
                            <div className={'input-div'}>
                                <label htmlFor={'reset-code'}>Code:</label>
                                <input
                                    id={'reset-code'}
                                    placeholder={'code'}
                                    autoComplete={'new-password'}
                                    type={'text'}
                                    required={true}
                                />
                            </div>
                            <div className={'input-div'}>
                                <label htmlFor={'new-password'}>
                                    New Password:
                                </label>
                                <input
                                    id={'new-password'}
                                    placeholder={'new password'}
                                    autoComplete={'new-password'}
                                    type={'password'}
                                    required={true}
                                />
                            </div>
                            <div className={'input-div'}>
                                <label htmlFor={'retype-password'}>
                                    Retype New Password:
                                </label>
                                <input
                                    id={'retype-password'}
                                    placeholder={'retype new password'}
                                    type={'password'}
                                    autoComplete={'new-password'}
                                    required={true}
                                />
                            </div>
                            <div className={'button-div'}>
                                <button
                                    disabled={false}
                                    type={"submit"}
                                    onMouseOver={e => (e.currentTarget.style.backgroundColor = '#25dad4')}
                                    onMouseOut={e => (e.currentTarget.style.backgroundColor = '#1ebdb7')}
                                >
                                    Confirm
                                </button>
                            </div>
                        </span>
                    </form>
                </div>
            </div>
        );
    };

    const StartResetView = () => {
        return (
            <div className={'reset-view'}>
                <div className={'header-div'}>
                    <h2>Reset your password</h2>
                </div>
                <div>
                    <form onSubmit={e => onClickResetPassword(e)}>
                        <span>
                            <div className={'input-div'}>
                                <label htmlFor={'reset-username'}>Username:</label>
                                <input
                                    id={'reset-username'}
                                    placeholder={'account email address'}
                                    type={'email'}
                                    required={true}
                                />
                            </div>
                            <div className={'button-div'}>
                                <button
                                    disabled={false}
                                    type={"submit"}
                                    onMouseOver={e => (e.currentTarget.style.backgroundColor = '#25dad4')}
                                    onMouseOut={e => (e.currentTarget.style.backgroundColor = '#1ebdb7')}
                                >
                                    Reset Password
                                </button>
                            </div>
                        </span>
                    </form>
                </div>
            </div>
        );
    };

    const View = () => {
        if (isLoading) {
            return <LoadingGIF message={'Loading...'}/>;

        } else if (resetConfirmationRequired) {
            return <ResetConfirmationView />;

        } else {
            return <StartResetView />;

        }
    };

    return (
        <div className={'sign-up-splash'}>
            <Container fluid={true} style={{cursor: 'default'}}>
                <Row xs={1} className={'row-1'}>
                    <Col className={'sign-up-col'}>
                        <div className={'splash-inner'}>
                            <div className={'new-membership-body'}>
                                <View />
                            </div>
                            <Footer />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );

};

export default ResetPassword;