/*Copyright 2024, Blake Donahoo, LLC, All rights reserved.*/
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { HashRouter, NavLink, Route, Routes, useNavigate } from "react-router-dom";
import { signOut } from 'aws-amplify/auth';
import { NavbarBrand, Navbar } from "reactstrap";
import logo from '../../assets/Logo3_raw_outline_2.png';
import {
    About, AccountSettings, ChangePassword, Connect, InvoiceMenu,
    Faq, GooglePrivacy, Home, HowTo, PartnerWithUs,
    PrivacyPolicy, ResetPassword, SignUp, TermsOfService,
    SignOutGIF
} from "../../pages";
import './nav-bar.scss';
import { getPageLocation } from "../../utils/getPageLocation";
import { apiStage, Environment } from "../../utils/constants";


const NavBar = ({isAuthenticated, userEmail, handleSignInModal}) => {
    const [isSigningOut, setIsSigningOut] = useState(false);
    const [currLocation, setCurrLocation] = useState("");

    async function amplifySignOut () {
        try {
            await signOut();
        } catch (error) {
            console.debug(`components.navbar.amplifySignOut Error`)
            console.error(error)
        }
    }

    const onClickSignOut = (event) => {
        event.preventDefault();
        window.location = `${getPageLocation(Environment, apiStage, '/')}`;
        setIsSigningOut(true);
        amplifySignOut().then(res => {
            console.debug('Signing out...')

        }).catch(error => {
            console.debug(`components.navbar.NavBar.onClickSignOut.amplifySignOut Error`)
            console.error(error)
        })
    };

    const NavigationRoutes = () => {
        // <Route
        //     path="/invoicing"
        //     element={<InvoiceMenu />}
        // />
        return (
            <Routes>
                <Route
                    path="/faq"
                    element={<Faq isAuthenticated={isAuthenticated}/>}
                />
                <Route
                    path="/partner-with-us"
                    element={<PartnerWithUs />}
                />
                <Route
                    path="/google-limited-use-disclosure"
                    element={<GooglePrivacy />}
                />
                <Route
                    path="/about"
                    element={<About />}
                />
                <Route
                    path="/privacy"
                    element={<PrivacyPolicy />}
                />
                <Route
                    path="/terms-of-use"
                    element={<TermsOfService />}
                />
                <Route
                    path="/settings"
                    element={<AccountSettings setIsSigningOut={setIsSigningOut}/>}
                />
                <Route
                    path="/how-to"
                    element={<HowTo />}
                />
                <Route
                    path="/bank-connections"
                    element={<Connect userEmail={userEmail} />}
                />
                <Route
                    path="/change-password"
                    element={<ChangePassword />}
                />
                <Route
                    path="/forgot-password"
                    element={<ResetPassword />}
                />
                <Route
                    path="/sign-out"
                    element={<SignOutGIF />}
                />
                <Route
                    path="/sign-in"
                    element={<Home />}
                />
                <Route
                    path="/sign-up"
                    element={<SignUp isAuthenticated={isAuthenticated} isSigningOut={isSigningOut} />}
                />
                <Route
                    path="/"
                    element={<Home />}
                />
                <Route
                    path=""
                    element={<Home />}
                />
            </Routes>
        );
    };

    const UnAuthenticatedBarLinks = ({currentPath}) => {
        let class_name = "main-nav-options";
        if (currentPath === '/') {
            class_name = "main-nav-options home-nav-options"
        }
        return (
            <div className={class_name} id={'navigation-bar'}>
                <ul>
                    <li>
                        <NavLink to={'/sign-up'}>
                            Sign Up
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={'/sign-in'} onClick={e => {handleSignInModal(e)}}>
                            Sign In
                        </NavLink>
                    </li>
                </ul>
            </div>
        );
    };

    const AuthenticatedBarLinks = ({currentPath}) => {
        let class_name = "main-nav-options";
        if (currentPath === '/') {
            class_name = "main-nav-options home-nav-options"
        }
        // <li>
        //     <NavLink to="/invoicing">
        //         Invoicing
        //     </NavLink>
        // </li>
        return (
            <div className={class_name} id={'navigation-bar'}>
                <ul>
                    <li>
                        <NavLink to="/" onClick={e => onClickSignOut(e)}>
                            Sign Out
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/settings">
                            Account Settings
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/bank-connections">
                            Bank Connections & Sheets
                        </NavLink>
                    </li>
                </ul>
            </div>
        );
    };

    const StandardLinks = () => {
        return (
            <ul>
                <li>
                    <NavLink to={'/faq'}>
                        FAQ
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'/partner-with-us'}>
                        Partner with us
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'/google-limited-use-disclosure'}>
                        Google Limited Use Disclosure
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'/about'}>
                        About Us
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'/privacy'}>
                        Privacy Policy
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'/terms-of-use'}>
                        Terms of use
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'/how-to'}>
                        How to
                    </NavLink>
                </li>
            </ul>
        );
    };

    const ActualRouter = () => {
        const location = useLocation();
        const currentPath = location.pathname;
        console.log(currentPath)
        let nav_class_name = "nav-bar crisscross";
        let app_logo_class = "app-logo-home";
        // let opt_class_name = "main-nav-options";
        if (currentPath === '/') {
            nav_class_name = "nav-bar home-nav crisscross";
            app_logo_class = "app-logo";
            // opt_class_name = "main-nav-options home-nav-options";
        }
        return (
            <Navbar className={nav_class_name} color={'dark'}>
                <NavbarBrand href={'/'}>
                    <img
                        alt={'logo'}
                        src={logo}
                        className={app_logo_class}
                    />
                    <div className={'logo-text'} hidden={!(currentPath === '/')}>
                        <h3>Bookkeeping simplified.</h3>
                    </div>
                </NavbarBrand>

                {isAuthenticated ? <AuthenticatedBarLinks currentPath={currentPath}/> : <UnAuthenticatedBarLinks currentPath={currentPath}/>}
                <NavigationRoutes />

            </Navbar>
        );
    };

    const Navigation = () => {
        return (
            <HashRouter>
                <ActualRouter />
            </HashRouter>
        );
    };

    return <Navigation />;

};

export default NavBar;