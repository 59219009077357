/*Copyright 2024, Blake Donahoo, All rights reserved.*/
export function isEmpty (value) {
    return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
    );
}

export function dotGet (value) {
    return !isEmpty(value) ? value : "";
}
