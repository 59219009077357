/*Copyright 2024, Blake Donahoo, LLC, All rights reserved.*/
import React from "react";
import './gifs.scss';
import { Spinner } from "reactstrap";

const LoadingGIF = ({message}) => {
    return (
        <div className={"GIF"}>
            <h1>{message}</h1>
            <Spinner color={'info'} className={'spinner'} />
        </div>
    );
};

export default LoadingGIF;