/*Copyright 2024, Blake Donahoo, LLC, All rights reserved.*/
import React, { useState } from "react";
import axios from "axios";
import './connection-wizard.scss';
import { getUserSession } from "../../utils/session";
import { getAndDebug, handleError } from "../../utils/exception-handling";
import { getMainServerEndpoint } from "../../utils/endpoints";
import { Environment, apiStage } from "../../utils/constants";
import { isEmpty } from "../../utils/objectTools";
import { FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";


const ConnectionWizard = ({
                              showWizard, onClickWizard, stepNumber,
                              setStepNumber, GoogleAuthSignIn, PlaidLinkDiv,
                              googleAuthStatus, allAccounts, onClickImportInstead
}) => {
    const [modalLoading, setModalLoading] = useState(false);

    if (!showWizard) {
        return null;
    }

    const changeStepNum = (e, num) => {
        e.preventDefault();
        if (num >= 5) {
            onClickWizard(e);

        } else if (stepNumber === 2 && num > 2) {
            // SUBMITTING A NEW COMPANY CREATION REQUEST
            // getAndDebug(`${document.querySelector('#new-company-input-value').value}`, 'querySelector company');
            const company = getAndDebug(`${document.querySelector('#new-company-input-value').value}`, 'querySelector company');
            // const company = getAndDebug(window.sessionStorage.getItem('new_company'), 'new_company');
            if (isEmpty(company)) {
                window.alert('You must enter a name to call this Google Sheet & Bank connection.\n\nSimplyBooks.io allows for the creation of several different sheets, this will help keep them separated.');

            } else {
                getUserSession().then(session => {
                    setModalLoading(true);
                    const newCoEndpoint = `${getMainServerEndpoint(Environment, apiStage)}/simplybooks/${session.userEmail}/create_new_company`;
                    const newCoConfig = {headers: {"Authorization": session.idToken, "Content-Type": "application/json"}};
                    const newCoPayload = {"company": `${company}`};
                    axios.post(newCoEndpoint, newCoPayload, newCoConfig).then(res => {
                        if (res.status !== 200) throw new Error(`Error creating new company: ${res.data['msg']}`)
                        window.sessionStorage.setItem("new_company", res.data['data']['company']);
                        setModalLoading(false);
                        setStepNumber(num);
                    }).catch(error => {
                        setModalLoading(false);
                        handleError(
                            'pages.connectionWizard.onClickNewCompany.newCoEndpoint',
                            error,
                            true,
                            'An error occurred while creating your new company.\n\nPlease try again.',
                            'caught error in pages.connectionWizard.onClickNewCompany.newCoEndpoint'
                        );

                    });

                }).catch(error => {
                    setModalLoading(false);
                    handleError(
                        'pages.connectionWizard.onClickNewCompany.getUserSession',
                        error,
                        true,
                        'An error occurred while creating your new company.\n\nPlease try again.',
                        'caught error in pages.connectionWizard.onClickNewCompany.getUserSession'
                    );

                });

            }

        } else if (stepNumber === 1 && !googleAuthStatus && num === 2) {
            window.alert('You must allow access to Google to continue.\n\nSimplyBooks.io creates and manages sheets within your Google account. For other features to work correctly, this is required.');

        } else {
            setStepNumber(num);
        }
    };

    const StepOne = () => {
        return (
            <div>
                <ModalHeader className={'wizard-header'}>
                    Connect to Google
                </ModalHeader>
                <ModalBody>
                    <div className={'wizard-inner-body'}>
                        <h5>
                            By signing into Google and allowing SimplyBooks permissions to GoogleSheets, we will be able to create, update, and
                            manage sheets on your behalf.
                        </h5>
                        <div className={'wizard-step-google-auth'}>
                            <GoogleAuthSignIn />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        style={{backgroundColor: !googleAuthStatus ? '#888888' : '#1fc6d0'}}
                        className={'wizard-change-step-button'}
                        disabled={!googleAuthStatus}
                        onClick={e => changeStepNum(e, 2)}
                    >
                        Next
                    </button>
                </ModalFooter>
            </div>
        );
    };

    const StepTwo = () => {
        const nextNumber = allAccounts.length ? 3 : 2.5;
        return (
            <div>
                <ModalHeader className={'wizard-header'}>
                    Create a new Sheet
                </ModalHeader>
                <ModalBody>
                    <div className={'wizard-inner-body'}>
                        <h5>
                            Create a new Google Sheet to associate the upcoming bank connection with.
                            This will enable you to direct your transactions to different sheets if you have more than one set of books to keep.
                        </h5>
                        <div className={'wizard-step-google-auth'}>
                            <FormGroup>
                                <Input
                                    type={'text'}
                                    required={true}
                                    placeholder={'this is basically the name of your sheet'}
                                    id={'new-company-input-value'}
                                    className={'new-company-input-value'}
                                    >
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <span>
                        <button
                            className={'wizard-change-step-button wizard-next wizard-button-default'}
                            onClick={e => changeStepNum(e, nextNumber)}
                        >
                            Next
                        </button>
                    </span>
                </ModalFooter>
            </div>
        );
    };

    const StepTwoAndAHalf = () => {
        return (
            <div>
                <ModalHeader className={'wizard-header'}>
                    Import data first?
                </ModalHeader>
                <ModalBody>
                    <div className={'wizard-inner-body'}>
                        <h6>
                            SimplyBooks will auto-categorize your transaction if you train your account algorithm first.
                            This can save you a lot of time if you have a previous set of books that have similar transactions
                            with the category that you want to use. Otherwise, SimplyBooks will auto-categorize future transactions
                            after it has learned from seeing what you do.
                        </h6>
                        <h6>
                            Click "Import Data" if you want to go that route, otherwise skip this by clicking "Next".
                            You'll also have the opportunity to do this later by going to the "Options" tab within your sheet.
                        </h6>
                        <div className={'wizard-step-google-auth'}>
                            <span>
                                <button
                                    className={'wizard-change-step-button wizard-button-default'}
                                    onClick={e => onClickImportInstead(e)}
                                >
                                    Import Data
                                </button>
                            </span>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <span>
                        <button
                            className={'wizard-change-step-button wizard-next wizard-button-default'}
                            onClick={e => changeStepNum(e, 3)}
                        >
                            Next
                        </button>
                    </span>
                </ModalFooter>
            </div>
        );
    };

    const StepThree = () => {
        const toggleYearSelection = (event, value) => {
            event.preventDefault();
            window.sessionStorage.setItem('year-selection', value)
        };
        return (
            <div>
                <ModalHeader className={'wizard-header'}>
                    Bank connection settings
                </ModalHeader>
                <ModalBody className={'wizard-inner-body'}>
                    <div>
                        <h5>
                            Choose how you want your bank connection to act.
                        </h5>
                        <h6>
                            Do you want to start a sheet for this year, or last year?
                        </h6>
                        <h6><i>
                            Starting a sheet for last year will create one sheet containing all of
                            your transactions from the year prior, and start a separate sheet containing all of your transactions from the beginning of this year
                            to the current date.
                        </i></h6>
                        <div className={'year-selection-div'}>
                            <FormGroup>
                                <Input onChange={e => toggleYearSelection(e, e.target.value)} type={'select'} defaultValue={'Start with this year or last year?'} id={'year-selection'}>
                                    <option key={0} value={'Start with this year or last year?'} hidden>Start with this year or last year?</option>
                                    <option key={1} value={'This year'}>This year</option>
                                    <option key={2} value={'Last year'}>Last year</option>
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <span>
                        <button
                            className={'wizard-change-step-button wizard-next wizard-button-default'}
                            onClick={e => changeStepNum(e, 4)}
                        >
                            Next
                        </button>
                    </span>
                </ModalFooter>
            </div>
        );
    };

    const StepFour = () => {

        // <button
        //     className={'wizard-change-step-button wizard-back wizard-button-default'}
        //     onClick={e => changeStepNum(e, 3)}
        // >
        //     Back
        // </button>

        if (stepNumber !== 4) {
            return <div></div>;

        } else {
            return (
                <div>
                    <ModalHeader className={'wizard-header'}>
                        Connect your bank
                    </ModalHeader>
                    <ModalBody className={'wizard-inner-body'}>
                        <div>
                            <h5>
                                SimplyBooks uses a secure, read-only connection with Plaid to communicate with your bank. Choose as many accounts as you have available
                                that you want SimplyBooks to keep track of. This will only count as 1 connection.
                            </h5>
                            <h6><i>
                                Skip this step by clicking "Finish" if you want to upload data from a different source to fast-forward the learning process on the algorithm for your account.
                            </i></h6>
                            <div className={'wizard-step-google-auth'}>
                                {PlaidLinkDiv}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    <span>
                        <button
                             className={'wizard-change-step-button wizard-back wizard-button-default'}
                             onClick={e => changeStepNum(e, 3)}
                         >
                             Back
                         </button>
                        <button
                            className={'wizard-change-step-button wizard-next wizard-button-default'}
                            onClick={e => changeStepNum(e, 5)}
                        >
                            Finish
                        </button>
                    </span>
                    </ModalFooter>
                </div>
            );
        }
    };

    const WizardLoading = () => {
        return (
            <div>
                <ModalHeader>
                    Loading ...
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className={'wizard-step-google-auth'}>
                            <Spinner color={'info'} className={'modal-spinner'} />
                        </div>
                    </div>
                </ModalBody>
            </div>
        );
    };

    const WizardBody = () => {
        let stepDiv = <WizardLoading />;
        if (!modalLoading) {
            if (stepNumber === 1 || stepNumber === 0) {
                stepDiv = <StepOne />;

            } else if (stepNumber === 2) {
                stepDiv = <StepTwo />;

            } else if (stepNumber === 2.5) {
                stepDiv = <StepTwoAndAHalf />;

            } else if (stepNumber === 3) {
                stepDiv = <StepThree />;

            } else {
                stepDiv = <StepFour />;

            }
        }
        return stepDiv;
    };

    return (
        <div>
            <Modal
                isOpen={showWizard}
                toggle={e => onClickWizard(e)}
                backdrop={true}
                keyboard={true}
                fullscreen={'md'}
                centered={true}
            >
                <WizardBody />
            </Modal>
        </div>
    );
};

export default ConnectionWizard;