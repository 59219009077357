/*Copyright 2024, Blake Donahoo, All rights reserved.*/
export function getMainServerEndpoint(environment, api_stage) {
    let mainEndpoint;
    if(environment === "local"){
        mainEndpoint = "http://localhost:8000"
        // mainEndpoint = "https://bz3hcbhosf.execute-api.us-east-1.amazonaws.com/test/"
    } else {
        switch(api_stage){
            case "local":
                mainEndpoint = "http://localhost:8000"
                break;
            case "test":
                mainEndpoint = "https://bmjibl8fe6.execute-api.us-east-1.amazonaws.com/test"
                break
            case "dev":
                mainEndpoint = ""
                break
            case "prod":
                mainEndpoint = "https://ibnt49twj7.execute-api.us-east-1.amazonaws.com/api"
                break
            default:
                mainEndpoint = "http://localhost:8000"
        }
    }
    return mainEndpoint;
}