/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React from "react";
import './page.scss';
import Footer from "../components/footer/footer";
import { Container, Row, Col } from 'reactstrap';

const PrivacyPolicy = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const Preamble = () => {
        return (
            <div>
                <p>
                    This privacy notice for Quantum Labs, LLC (doing business as SimplyBooks.io) ("<strong>we</strong>", "<strong>us</strong>", or "<strong>our</strong>"), describes
                    how and why we might collect, store, use and/or share ("<strong>process</strong>") your information when you use our services ("<strong>Services</strong>"), such as when you:
                </p>
                <ul>
                    <li>Visit our website at https://simplybooks.io, or any website of ours that links to this privacy notice.</li>
                    <li>Engage with us in other related ways, including any sales, marketing, or events.</li>
                </ul>
                <br />
                <p>
                    <strong>Questions or concerns?</strong> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices,
                    please do not use our Services. If you still have any questions or concerns, please contact us at support@simplybooks.io.
                </p>
            </div>
        );
    };

    const Summary = () => {
        return (
            <div>
                <br />
                <h2>Summary of Key Points</h2>
                <p>
                    <strong>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by reading below in the appropriate section.</strong>
                </p>
                <br />
                <p>
                    <strong>(1) What personal information do we process?</strong> When you visit, use, or navigate our Services,
                    we may process personal information depending on how you interact with us and the Services,
                    the choices you make, and the products and features you use.
                </p>
                <p>
                    <strong>(2) Do we process any sensitive personal information?</strong> We may process sensitive personal
                    information when necessary with your consent or as otherwise permitted by applicable law.
                </p>
                <p>
                    <strong>(3) Do we collect any information from third parties?</strong> We do not collect any information from
                    third parties, except as described in our Terms of Use, as a means of providing products and services directly to you with your consent.
                </p>
                <p>
                    <strong>(4) How do we process your information?</strong> We process your information to provide, improve, and administer our Services,
                    communicate with you, for security and fraud prevention, and to comply with applicable state and federal laws. We may also process
                    your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.
                </p>
                <p>
                    <strong>(5) In what situations and with which parties do we share personal information?</strong> We may share information in
                    specific situations and with specific third parties.
                </p>
                <p>
                    <strong>(6) How do we keep your information safe?</strong> We have organizational and technical processes and procedures
                    in place to protect your personal information. However, no electronic transmission over the internet or information storage
                    technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized
                    third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.
                </p>
                <p>
                    <strong>(7) What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law
                    may mean you have certain rights regarding your personal information.
                </p>
                <p>
                    <strong>(8) How do you exercise your rights?</strong> The easiest way to exercise your rights is by visiting https://simplybooks.io,
                    or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
                </p>
                <br />
                <p>
                    <strong><i>Continue reading below for a more detailed explanation of the points summarized above.</i></strong>
                </p>
                <br />
            </div>
        );
    };

    const Section1 = () => {
        return (
            <div>
                <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
                <h3>Personal information you disclose to us.</h3>
                <p>
                    We collect personal information that you voluntarily provide to us when you register on the Services, express an interest
                    in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
                </p>
                <p>
                    <strong>Personal Information Provided by You.</strong>
                </p>
                <p>
                    <strong>In Short:</strong> We collect personal information that you provide to us.
                </p>
                <p>
                    We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information
                    about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
                </p>
                <p>
                    <strong>Personal Information Provided by You:</strong> The personal information that we collect depends on the context of your interactions
                    with us and the Services, the choices you make, and the products and features you use. The personal information we collect my include the following:
                </p>
                <ul>
                    <li>names</li>
                    <li>phone numbers</li>
                    <li>email addresses</li>
                </ul>
                <p>
                    <strong>Sensitive Information:</strong> When necessary, with your consent or as otherwise permitted by applicable law, we process
                    the following categories of sensitive information:
                </p>
                <ul>
                    <li>financial data</li>
                </ul>
                <p>
                    <strong>Payment Data:</strong> We may collect data necessary to process your payment if you choose to make purchases, such as your payment
                    instrument number, and the security code associated with your payment instrument. All payment data is handled and stored by Stripe. You may find their privacy notice
                    link(s) here: https://stripe.com/privacy
                </p>
                <p>
                    All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
                </p>
                <br />
            </div>
        );
    };

    const Section2 = () => {
        return (
            <div>
                <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
                <p>
                    <strong>In Short:</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security
                    and fraud prevention, and to comply with the law. We may also process your information for other purposes with your consent.
                </p>
                <p>
                    <strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong>
                </p>
                <ul>
                    <li><strong>To facilitate account creation and authentication and otherwise manage user accounts.</strong> We may process your information
                        so that you can create and log in to your account, as well as keep your account in working order.</li>
                    <li><strong>To deliver and facilitate delivery of services to the user.</strong> We may process your information to provide you with the requested service.</li>
                </ul>
                <br />
            </div>
        );
    };

    const Section3 = () => {
        return (
            <div>
                <h2>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
                <p>
                    <strong>In Short:</strong> We may share information in specific situations described in this section and/or with the following third parties.
                </p>
                <p>We may need to share your personal information in the following situations:</p>
                <ul>
                    <li><strong>Business Transfers.</strong></li> We may share or transfer your information in connection with,
                    or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                </ul>
                <br />
            </div>
        );
    };

    const Section4 = () => {
        return (
            <div>
                <h2>4. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                <p>
                    <strong>In Short:</strong> We keep your information for as long as necessary to fulfill the purposes outlined in this
                    privacy notice unless otherwise requested by law.
                </p>
                <p>
                    We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required
                    or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the
                    period of time in which users have an account with us.
                </p>
                <p>
                    When we have no ongoing legitimate business need to proc ess your personal information, we will either delete or anonymize such information, or, if this is not possible
                    (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further
                    processing until deletion is possible.
                </p>
            </div>
        );
    };

    const Section5 = () => {
        return (
            <div>
                <h2>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                <p>
                    <strong>In Short:</strong> We aim to protect your personal information through a system of organizational and technical security measures.
                </p>
                <p>
                    We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process.
                    However, despite our safeguards and efforts to secure your information, no electronic transmission over the internet or information storage technology can be
                    guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security
                    and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from
                    our Services is at your own risk. You should only access the Services within a secure environment.
                </p>
            </div>
        );
    };

    const Section6 = () => {
        return (
            <div>
                <h2>6. DO WE COLLECT INFORMATION FROM MINORS?</h2>
                <p>
                    <strong>In Short:</strong> We do not knowingly collect data from or market to children under 18 years of age.
                </p>
                <p>
                    We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information.
                    By using the Services, you represent that you are at least 18 years of age or older, or that you are the parent or guardian of such a minor and consent
                    to such minor dependent's use of the Services. If we learn that personal information from users less that 18 year of age has been collected, we will
                    deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under
                    the age of 18, please contact us at support@simplybooks.io
                </p>
            </div>
        );
    };

    const Section7 = () => {
        return (
            <div>
                <h2>7. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                <p>
                    <strong>In Short:</strong> You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.
                </p>
                <p>
                    <strong><u>Withdrawing your consent.</u></strong> If we are relying on your consent to process your personal information, which
                    may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can
                    withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
                </p>
                <p>
                    However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing
                    of your personal information conducted in reliance on lawful processing grounds other than consent.
                </p>
            </div>
        );
    };

    const Section8 = () => {
        return (
            <div>
                <h2>8. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                <p>
                    Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your
                    privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing
                    DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online.
                    If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
                </p>
                <p>
                    California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for recognizing or
                    honoring DNT signals, we do not respond to them at this time.
                </p>
            </div>
        );
    };

    const Section9 = () => {
        return (
            <div>
                <h2>9. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
                <p>
                    <strong>In Short:</strong> If you are a resident of California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky,
                    Montana, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to request access to and receive details
                    about the personal information we maintain about you and how we have processed it, correct inaccuracies, get a copy of, or delete your personal
                    information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited
                    in come circumstances by applicable law. More information is provided below.
                </p>
                <h3>Categories of personal information we collect</h3>
                <p>We have collected the following categories of personal information in the past twelve (12) months:</p>
                <div>
                    <table
                        width={1600}
                        cellSpacing={5}
                        cellPadding={5}
                        border={0}
                        align={'left'}
                        bgcolor={'#FFFFFF'}
                        style={{
                            marginBottom: '30px',
                            maxWidth: '100%',
                            width: '100%',
                            borderRadius: '5px'
                        }}>
                        <thead>
                        <tr>
                            <th style={{width: '20%'}}>Category</th>
                            <th style={{width: '70%'}}>Examples</th>
                            <th style={{width: '10%'}}>Collected</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td bgcolor={'#eee'}>A. Identifiers</td>
                            <td bgcolor={'#eee'}>
                                Contact details, such as real name, alias, postal address,
                                telephone or mobile contact number, unique personal identifier, online identifier,
                                Internet Protocol address, email address, and account name.
                            </td>
                            <td bgcolor={'#eee'} style={{textAlign: 'center'}}>YES</td>
                        </tr>
                        <tr>
                            <td>B. Personal Information as defined in the California Customer Records statute</td>
                            <td>
                                Name, contact information, education, employment, employment history, and financial information.
                            </td>
                            <td style={{textAlign: 'center'}}>NO</td>
                        </tr>
                        <tr>
                            <td bgcolor={'#eee'}>C. Protected classification characteristics under state or federal law</td>
                            <td bgcolor={'#eee'}>
                                Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data.
                            </td>
                            <td bgcolor={'#eee'} style={{textAlign: 'center'}}>NO</td>
                        </tr>
                        <tr>
                            <td>D. Commercial information</td>
                            <td>
                                Transaction information, purchase history, financial details, and payment information
                            </td>
                            <td style={{textAlign: 'center'}}>YES</td>
                        </tr>
                        <tr>
                            <td bgcolor={'#eee'}>E. Biometric information</td>
                            <td bgcolor={'#eee'}>Fingerprints and voiceprints</td>
                            <td bgcolor={'#eee'} style={{textAlign: 'center'}}>NO</td>
                        </tr>
                        <tr>
                            <td>F. Internet or other similar network activity</td>
                            <td>
                                Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements
                            </td>
                            <td style={{textAlign: 'center'}}>NO</td>
                        </tr>
                        <tr>
                            <td bgcolor={'#eee'}>G. Geolocation data</td>
                            <td bgcolor={'#eee'}>Device location</td>
                            <td bgcolor={'#eee'} style={{textAlign: 'center'}}>NO</td>
                        </tr>
                        <tr>
                            <td>H. Audio, electronic, sensory, or similar information</td>
                            <td>Images and audio, video or call recordings created in connection with our business activities</td>
                            <td style={{textAlign: 'center'}}>NO</td>
                        </tr>
                        <tr>
                            <td bgcolor={'#eee'}>I. Professional or employment-related information</td>
                            <td bgcolor={'#eee'}>
                                Business contact details in order to provide you our Services at a business level or job title, work history, and professional
                                qualifications if you apply for a job with us
                            </td>
                            <td bgcolor={'#eee'} style={{textAlign: 'center'}}>NO</td>
                        </tr>
                        <tr>
                            <td>J. Education information</td>
                            <td>Student records and directory information</td>
                            <td style={{textAlign: 'center'}}>NO</td>
                        </tr>
                        <tr>
                            <td bgcolor={'#eee'}>K. Inferences drawn from collected personal information</td>
                            <td bgcolor={'#eee'}>
                                Inferences drawn from any of the collected personal information listed above to create a profile or summary
                                about, for example, an individual's preferences and characteristics
                            </td>
                            <td bgcolor={'#eee'} style={{textAlign: 'center'}}>NO</td>
                        </tr>
                        <tr>
                            <td>L. Sensitive personal information</td>
                            <td>Financial information including account access details</td>
                            <td style={{textAlign: 'center'}}>NO</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br />
                <p>
                    We only collect sensitive personal information, as defined by applicable privacy laws or the purposes allowed by law or with your consent.
                    Sensitive personal information may be used, or disclosed to a service provider or contractor, for additional, specified purposes. You may have the
                    right to limit the use or disclosure of your sensitive personal information. We do not collect or process sensitive personal information for the purpose
                    of inferring characteristics about you.
                </p>
                <p>
                    We may also collect other personal information outside of these categories through instances where you interact with us in person, online,
                    or by phone or mail in the context of:
                </p>
                <ul>
                    <li>Receiving help through our customer support channels;</li>
                    <li>Participation in customer surveys or contests; and</li>
                    <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
                </ul>
                <p>
                    We will use and retain the collected personal information as needed to provide the Services or for:
                </p>
                <ul>
                    <li>Category H - Sensory data is not collected;</li>
                    <li>Category L - "Financial Information" meaning transaction history for the purpose of bookkeeping and bookkeeping automation.</li>
                </ul>
                <h3>Sources of Personal Information</h3>
                <p>Read more about our sources of personal information in section (1) titled "WHAT INFORMATION DO WE COLLECT?"</p>
                <h3>How We Use and Share Personal Information</h3>
                <p>
                    Read more about how we use your personal information in section (2) titled "HOW DO WE PROCESS YOUR INFORMATION?"
                </p>
                <h3>Will your information be shared with anyone else?</h3>
                <p>
                    We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider.
                    Learn more about how we disclose personal information in section (3) titled "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
                </p>
                <p>
                    We may use your personal information for our own business purposes, such as for undertaking internal research for technological
                    development and demonstration. This is not considered to be "selling" of your personal information.
                </p>
                <p>
                    We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding (12) months.
                    We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.
                </p>
                <h3>Your Rights</h3>
                <p>
                    You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.
                    These rights include:
                </p>
                <ul>
                    <li><strong>Right to know</strong> whether or not we are processing your personal data</li>
                    <li><strong>Right to access</strong> your personal data</li>
                    <li><strong>Right to correct</strong> inaccuracies in your personal data</li>
                    <li><strong>Right to request</strong> the deletion of your personal data</li>
                    <li><strong>Right to obtain a copy</strong> of the personal data you previously shared with us</li>
                    <li><strong>Right to non-discrimination</strong> for exercising your rights</li>
                    <li><strong>Right to opt out</strong> of the processing of your personal data if it is used for targeted advertising (or sharing as
                        defined under California's privacy law), the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant
                        effects ("profiling")</li>
                </ul>
                <p>
                    Depending upon the state where you live, you may also have the following rights:
                </p>
                <ul>
                    <li>Right to obtain a list of the categories of third parties to which we have disclosed personal data (as permitted by applicable law, including California's and Delaware's privacy law)</li>
                    <li>Right to obtain a list of specific third parties to which we have disclosed personal data (as permitted by applicable law, including Oregon's privacy law)</li>
                    <li>Right to limit use and disclosure of sensitive personal data (as permitted by applicable law, including California's privacy law)</li>
                    <li>Right to opt out of the collection of sensitive data and personal data collected through the operation of a voice or facial recognition feature (as permitted by applicable law, including Florida's privacy law)</li>
                </ul>
                <h3>How to Exercise Your Rights</h3>
                <p>
                    To exercise these rights, you can contact us by visiting https://simplybooks.io and navigating to your account settings, or by emailing us
                    at support@simplybooks.io, or by referring to the contact details at the bottom of this notice.
                </p>
                <p>
                    We will honor your opt-out preferences if you enact the Global Privacy Control (GPC) on your browser.
                </p>
                <p>
                    Under certain US state data protection laws, you can delegate an authorized agent to make a request on your behalf. We may deny a request from an
                    authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with applicable laws.
                </p>
                <h3>Request Verification</h3>
                <p>
                    Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we may have the information in our system.
                    We will only use personal information provided in your request to verify you identity or authority to make the request. However, if we cannot verify your identity from the
                    information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security
                    or fraud-prevention purposes.
                </p>
                <p>
                    If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request and the agent will need
                    to provide a written and signed permission from you to submit such request on your behalf.
                </p>
                <h3>Appeals</h3>
                <p>
                    Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our decision by emailing support@simplybooks.io.
                    We will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is
                    denied, you may submit a complaint to your state attorney general.
                </p>
                <h3>California "Shine The Light" Law</h3>
                <p>
                    California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from
                    us, once a year and free of charge, information about categories of personal information (if any) we disclose to third parties for direct marketing
                    purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California
                    resident and would like to make such a request, please submit your request in writing to use by using the contact details provided in the section
                    "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                </p>
            </div>
        );
    };

    const Section10 = () => {
        return (
            <div>
                <h2>10. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
                <p>
                    <strong>In Short:</strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.
                </p>
                <p>
                    We may update this privacy policy notice form time to time. The updated version will be indicated by an updated "Revised" date at the top of this privacy notice.
                    If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a
                    notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
                </p>
            </div>
        );
    };

    const Section11 = () => {
        return (
            <div>
                <h2>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
                <p>
                    If you have questions or comments about this notice, you may email us at support@simplybooks.io or contact us by post at:
                </p>
                <br />
                <div>
                    <p style={{marginTop: '-20px'}}>Quantum Labs, LLC</p>
                    <p style={{marginTop: '-20px'}}>1004 Memorial dr.</p>
                    <p style={{marginTop: '-20px'}}>Wylie, TX 75098</p>
                    <p style={{marginTop: '-20px'}}>United States</p>
                </div>
            </div>
        );
    };

    const Section12 = () => {
        return (
            <div>
                <h2>12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
                <p>
                    You have the right to request access to the personal information we collect from you, details about how we have processed it,
                    correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information.
                    These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please visit
                    https://simplybooks.io and navigate to "Account Settings" using the top tool-bar.
                </p>
            </div>
        );
    };


    return (
        <div className={'page-splash'}>
            <Container fluid={true} style={{cursor: 'default'}}>
                <Row xs={1} className={'row-1'}>
                    <Col>
                        <div className={'splash-inner'}>
                            <header>
                                <h2><strong>Privacy Policy</strong></h2>
                                <h5><i>Last Updated June 02, 2024</i></h5>
                            </header>
                            <div className={'page-body'}>
                                <div className={'privacy-policy'}>
                                    <Preamble />
                                    <Summary />
                                    <hr style={{width: '100%'}}></hr>
                                    <Section1 />
                                    <hr style={{width: '100%'}}></hr>
                                    <Section2 />
                                    <hr style={{width: '100%'}}></hr>
                                    <Section3 />
                                    <hr style={{width: '100%'}}></hr>
                                    <Section4 />
                                    <hr style={{width: '100%'}}></hr>
                                    <Section5 />
                                    <hr style={{width: '100%'}}></hr>
                                    <Section6 />
                                    <hr style={{width: '100%'}}></hr>
                                    <Section7 />
                                    <hr style={{width: '100%'}}></hr>
                                    <Section8 />
                                    <hr style={{width: '100%'}}></hr>
                                    <Section9 />
                                    <hr style={{width: '100%'}}></hr>
                                    <Section10 />
                                    <hr style={{width: '100%'}}></hr>
                                    <Section11 />
                                    <hr style={{width: '100%'}}></hr>
                                    <Section12 />
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );

};

export default PrivacyPolicy;