/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { GoogleClientId, StripePublic } from "./utils/constants";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { config } from "./amplify/config";
import { Amplify } from "aws-amplify";

Amplify.configure(config);

const stripePromise = await loadStripe(`${StripePublic}`);
const stripeOptions = {
    mode: 'setup',
    currency: 'usd',
    paymentMethodCreation: 'manual'
};  // NEW - Required for createConfirmationToken & Elements as opposed to createSetupIntent & CardElement

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Elements stripe={stripePromise} options={stripeOptions}>
            <GoogleOAuthProvider clientId={`${GoogleClientId}`}>
                <App
                    stripePublic={`${StripePublic}`}
                    stripe={stripePromise}
                />
            </GoogleOAuthProvider>
        </Elements>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
