/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React, { useState } from 'react';
import './page.scss';
import { withAuthenticator } from "@aws-amplify/ui-react";
import { ButtonGroup, ButtonToolbar, Col, Container, Row } from "reactstrap";
import Footer from "../components/footer/footer";
import LoadingGIF from "../components/GIFS/loading";
import { isEmpty } from "../utils/objectTools";


const InvoiceMenu = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const onClickCreateNewInvoice = (event) => {
        event.preventDefault();
        console.debug(`---> onClickCreateNewInvoice`)

    };

    const TaskBar = () => {
        let createNew = <button
            role={'link'}
            onClick={e => onClickCreateNewInvoice(e)}
            style={{backgroundColor: '#239c13', cursor: 'pointer'}}
            onMouseOver={e => e.currentTarget.style.backgroundColor = '#2bc716'}
            onMouseOut={e => e.currentTarget.style.backgroundColor = '#239c13'}
        >
            + New
        </button>;

        return (
            <div className={'invoice-task-bar'}>
                <ButtonToolbar>
                    <ButtonGroup className={'button-group-create'}>
                        {createNew}
                    </ButtonGroup>
                </ButtonToolbar>
            </div>
        );
    };

    const Menu = () => {
        return (
            <div className={"connection-view"}>
                <div className={'connection-view-header'}>
                    <h2>Invoicing</h2>
                    <TaskBar />
                </div>

            </div>
        );
    };

    const View = () => {
        if (isLoading) {
            return <LoadingGIF message={'Loading...'} />;

        } else {
            return <Menu />;
        }
    };

    return (
        <div className={'sign-up-splash'}>
            <Container fluid={true} style={{cursor: 'default'}}>
                <Row xs={1} className={'row-1'}>
                    <Col className={'sign-up-col'}>
                        <div className={'splash-inner'}>
                            <div className={'new-membership-body'}>
                                <View />
                            </div>
                            <Footer />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );

};

export default withAuthenticator(InvoiceMenu);