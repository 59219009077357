/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React, { useState, useEffect } from 'react';
import axios from "axios";
import './page.scss';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { getMainServerEndpoint } from "../utils/endpoints";
import { handleError } from "../utils/exception-handling";
import { Environment, apiStage } from "../utils/constants";
import { getUserSession } from "../utils/session";
import { objectDebug } from "../utils/exception-handling";
import { isEmpty } from "../utils/objectTools";
import { getPageLocation } from "../utils/getPageLocation";
import { Col, Container, Row } from "reactstrap";
import Footer from "../components/footer/footer";
import LoadingGIF from "../components/GIFS/loading";
import MembershipPlanSelection from "../components/settings/current-plan";
import ConnectionLimit from "../components/settings/connection-limit";
import AutoCategorization from "../components/settings/auto-categorization";
import UpdatePaymentMethod from "../components/settings/payment-method";


const AccountSettings = ({ isSigningOut }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [autoCat, setAutoCat] = useState(true);
    const [joinDate, setJoinDate] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [reRender, setReRender] = useState(false);

    const [membershipLabel, _setMembershipLabel] = useState('');
    const [startingMembership, setStartingMembership] = useState('');
    const [stripeData, setStripeData] = useState({
        customer: '',
        product: '',
        subscription: {id: '', cycle_end: ''},
        payment_method: {id: '', last4: 'xxxx'}
    });
    const [connectionLimit, setConnectionLimit] = useState('1');
    const [startingLimit, setStartingLimit] = useState('1');

    const translateMembershipToLabel = (membership_code) => {
        if (membership_code === 'basic_monthly') {
            return 'Basic Monthly';
        } else if (membership_code === 'basic_annual') {
            return `Basic Annual`;
        } else if (membership_code === '30_day_trial') {
            return `30 Day Trial`;
        } else if (membership_code === 'cancel') {
            return 'Cancelled';
        }
    };

    const translateLabelToMembership = (label) => {
        if (label === 'Basic Monthly') {
            return 'basic_monthly';
        } else if (label === `Basic Annual`) {
            return 'basic_annual';
        } else if (label === `30 Day Trial`) {
            return '30_day_trial';
        } else if (label === 'Cancelled') {
            return 'cancel';
        }
    };

    useEffect(() => {
        const setMembershipLabel = (membership_code) => {
            const label = translateMembershipToLabel(membership_code);
            _setMembershipLabel(`${label}`);
        };
        try {

            getUserSession().then(session => {
                const accountEndpoint = `${getMainServerEndpoint(Environment, apiStage)}/simplybooks/${session.userEmail}/account_status`;
                setEmail(`${session.userEmail}`)
                const accountConfig = {headers: {"Authorization": session.idToken}};
                axios.get(accountEndpoint, accountConfig).then(res => {
                    objectDebug('accountStatusResponse', res.data);
                    if (res.status !== 200) throw new Error(`Account Status Endpoint returned a ${res.status} code.`);
                    const resData = res.data['data'];
                    // objectDebug('accountStatusResponse [data]', res.data['data']);
                    setMembershipLabel(resData['membership']);
                    setStartingMembership(`${translateMembershipToLabel(resData['membership'])}`);
                    setConnectionLimit(`${resData['connection_limit']}`);
                    setStartingLimit(`${resData['connection_limit']}`);
                    setJoinDate(resData['join_date']);
                    setPhone(resData['phone']);
                    setAutoCat(resData['auto_cat']);
                    setStripeData(resData['stripe']);
                    objectDebug('setStripeData ', resData['stripe']);
                    setIsLoading(false);
                }).catch(error => {
                    handleError(
                        'components.edit-membership.EditMembership.memberStatus.accountEndpoint',
                        error,
                        false,
                        '',
                        'Caught error from memberStatus.accountEndpoint',
                        null
                    );
                    setIsLoading(false);
                })

            }).catch(error => {
                handleError(
                    'components.edit-membership.EditMembership.memberStatus.getUserSession',
                    error,
                    false,
                    '',
                    'Caught error from memberStatus.getUserSession',
                    null
                );
                setIsLoading(false);
            });


        } catch (error) {
            handleError(
                'pages.membership.EditMembershipView.memberStatus',
                error,
                false,
                '',
                'Caught error from memberStatus',
                null
            );
            setIsLoading(false);
        }
    }, [startingMembership, reRender]);

    const onClickUpdateSubscription = (event, payload) => {
        event.preventDefault();
        try {
            getUserSession().then(session => {
                setIsLoading(true);
                const updateSubscriptionEndpoint = `${getMainServerEndpoint(Environment, apiStage)}/simplybooks/${session.userEmail}/update_subscription`;
                const config = {headers: {"Authorization": session.idToken, "Content-Type": "application/json"}};
                axios.post(updateSubscriptionEndpoint, payload, config).then(res => {
                    objectDebug('updateSubscriptionEndpoint', res.data);

                    if (res.status === 202) {
                        window.alert(`[${res.status}] Unable to update membership\n\n${res.data['msg']}`)
                        setIsLoading(false);

                    } else if (res.status !== 200) {
                        throw new Error(`Update Subscription Endpoint returned a ${res.status} code.`);

                    } else {
                        window.alert(`${res.data['msg']}`);
                        if (payload['type'] === "connection_limit") {
                            setConnectionLimit(`${payload['value']}`);
                            setStartingLimit(`${payload['value']}`);

                        } else if (payload['type'] === "plan") {
                            setStartingMembership(`${translateMembershipToLabel(payload['value'])}`);
                            const label = translateMembershipToLabel(payload['value']);
                            _setMembershipLabel(`${label}`);

                        } else if (payload['type'] === "auto_cat") {
                            setAutoCat(payload['value']);
                        }

                    }

                    setIsLoading(false);
                    setReRender(!reRender)

                }).catch(error => {
                    handleError(
                        'pages.membership.EditMembershipView.onClickUpdateSubscription.updateSubscriptionEndpoint',
                        error,
                        false,
                        '',
                        'Caught error from onClickUpdateSubscription.updateSubscriptionEndpoint',
                        null
                    );
                    setIsLoading(false);
                    setReRender(!reRender)
                })

            }).catch(error => {
                handleError(
                    'pages.membership.EditMembershipView.onClickUpdateSubscription.getUserSession',
                    error,
                    false,
                    '',
                    'Caught error from onClickUpdateSubscription.getUserSession',
                    null
                );
                setIsLoading(false);
                setReRender(!reRender)
            })

        } catch (error) {
            handleError(
                'pages.membership.EditMembershipView.onClickUpdateSubscription',
                error,
                true,
                'An Error occurred while attempting to update your subscription.\n\nPlease try again later or contact support:\nSupport@qlab-innovations.com',
                'Caught error from onClickUpdateSubscription',
                null
            );
            setIsLoading(false);
            setReRender(!reRender)
        }
    };

    const onClickChangePassword = (event) => {
        event.preventDefault();
        window.location = `${getPageLocation(Environment, apiStage, 'change-password')}`;
    };

    const ChangePasswordButton = () => {
        return (
            <div className={'change-password-button-div'}>
                <button
                    disabled={false}
                    onMouseOver={e => (e.currentTarget.style.backgroundColor = '#f2ab50')}
                    onMouseOut={e => (e.currentTarget.style.backgroundColor = '#eb9b34')}
                    onClick={e => onClickChangePassword(e)}
                >
                    Change Password
                </button>
            </div>
        );
    };

    const CancelMembershipButton = () => {
        if (membershipLabel === "Cancelled") {
            return (
                <div className={'cancel-membership-button-div'}>
                    <button
                        disabled={false}
                        onMouseOver={e => (e.currentTarget.style.backgroundColor = '#f55353')}
                        onMouseOut={e => (e.currentTarget.style.backgroundColor = '#eb3b3b')}
                        onClick={e => onClickUpdateSubscription(e, {
                            'type': 'plan',
                            'value': 'resume',
                            'customer': stripeData['customer'],
                            'subscription': stripeData['subscription']['id']
                        })
                        }
                    >
                        Resume Membership
                    </button>
                </div>
            );
        } else if (membershipLabel === "30 Day Trial") {
            return (<div className={'cancel-membership-button-div'}><br /><br /></div>);

        } else {
            return (
                <div className={'cancel-membership-button-div'}>
                    <button
                        disabled={false}
                        onMouseOver={e => (e.currentTarget.style.backgroundColor = '#f55353')}
                        onMouseOut={e => (e.currentTarget.style.backgroundColor = '#eb3b3b')}
                        onClick={e => onClickUpdateSubscription(e, {
                            'type': 'plan',
                            'value': 'cancel',
                            'customer': stripeData['customer'],
                            'subscription': stripeData['subscription']['id']
                        })
                        }
                    >
                        Cancel Membership
                    </button>
                </div>
            );
        }
    };

    const NextInvoice = () => {
        if (membershipLabel === "Cancelled") {
            return (
                <div className={'next-invoice-div-cancelled'}>
                        <span>
                            <strong>Your Membership is scheduled to cancel on:</strong> {stripeData['subscription']['cycle_end']}
                            <p>After the cancellation date, your account will be removed.</p>
                            <p>Until then, you can use <strong>'Resume Membership'</strong> to undo this action.</p>
                        </span>
                </div>
            );

        } else if (membershipLabel === "30 Day Trial") {
            return (
                <div className={'next-invoice-div-trial'}>
                        <span>
                            <strong>Your trial ends on:</strong> {stripeData['subscription']['cycle_end']}
                            <p>After the end date, your account will be removed unless you choose a monthly or annual subscription.</p>
                        </span>
                </div>
            );

        } else {
            return (
                <div className={'next-invoice-div'}>
                        <span>
                            <strong>Next invoice:</strong> {stripeData['subscription']['cycle_end']}
                        </span>
                </div>
            );
        }
    };

    const Amount = () => {
        if (membershipLabel === "Cancelled") {
            return (<div></div>);

        } else {
            let total = 0.00;
            if (!isEmpty(stripeData['invoice']['total'])) {
                total = stripeData['invoice']['total'];
            }
            return (
                <div className={'next-invoice-amount-div'}>
                        <span>
                            <strong>Amount:</strong> ${total}
                        </span>
                </div>
            );
        }
    };

    const MainView = () => {
        return (
            <div className={'account-settings-main-view'}>
                <div className={'top-div-1'}>
                    <div className={'top-div-form-1'}>
                        <NextInvoice />
                        <Amount />
                        <hr />
                        <MembershipPlanSelection
                            membershipLabel={membershipLabel}
                            startingMembership={startingMembership}
                            onClickUpdateSubscription={onClickUpdateSubscription}
                            translateLabelToMembership={translateLabelToMembership}
                            stripeCustId={stripeData['customer']}
                            stripeSubId={stripeData['subscription']['id']}
                        />
                        <hr></hr>
                        <ConnectionLimit
                            connectionLimit={connectionLimit}
                            startingLimit={startingLimit}
                            onClickUpdateSubscription={onClickUpdateSubscription}
                            stripeCustId={stripeData['customer']}
                            stripeSubId={stripeData['subscription']['id']}
                            membershipLabel={membershipLabel}
                        />
                        <hr />
                        <AutoCategorization
                            stripeCustId={stripeData['customer']}
                            stripeSubId={stripeData['subscription']['id']}
                            onClickUpdateSubscription={onClickUpdateSubscription}
                            autoCat={autoCat}
                        />
                        <hr />
                        <div className={'payment-method-label-div'}>
                            <span>
                                <strong>Payment Method:</strong> {stripeData['payment_method']['last4']}
                            </span>
                        </div>
                        <UpdatePaymentMethod stripeData={stripeData}/>
                        <hr />
                        <div className={'password-and-sub-label-div'}>
                            <span>
                                <strong>Password & Subscription:</strong>
                            </span>
                        </div>
                        <ChangePasswordButton />
                        <CancelMembershipButton />

                    </div>
                </div>
            </div>
        );
    };

    if (isLoading) {
        const loaderMessage = isSigningOut ? 'Signing you out...' : 'Loading your account settings...';
        return (
            <div className={'sign-up-splash'}>
                <Container fluid={true} style={{cursor: 'default'}}>
                    <Row xs={1} className={'row-1'}>
                        <Col className={'sign-up-col'}>
                            <div className={'splash-inner'}>
                                <div className={'new-membership-body'}>
                                    <LoadingGIF message={loaderMessage}/>
                                </div>
                                <Footer />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );

    } else {
        return (
            <div className={'account-settings-splash'}>
                <Container fluid={true} style={{cursor: 'default'}}>
                    <Row xs={1} className={'row-1'}>
                        <Col className={'sign-up-col'}>
                            <div className={'splash-inner'}>
                                <header>
                                    <h2>Account Settings</h2>
                                </header>
                                <div className={'new-membership-body'}>
                                    <MainView />
                                </div>
                                <Footer />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }


};

export default withAuthenticator(AccountSettings);