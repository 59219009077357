/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React, { useState } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';
import { handleError } from "../utils/exception-handling";
import { updatePassword } from "@aws-amplify/auth";
import { getPageLocation } from "../utils/getPageLocation";
import { apiStage, Environment } from "../utils/constants";
import { Col, Container, Row } from "reactstrap";
import LoadingGIF from "../components/GIFS/loading";
import Footer from "../components/footer/footer";

const ChangePassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
    async function handleUpdatePassword(oldPassword, newPassword) {
        try {
            return await updatePassword({ oldPassword, newPassword });
        } catch (error) {
            handleError(
                'pages.change-password.handleUpdatePassword',
                error,
                false,
                '',
                'Caught error in handleUpdatePassword'
            );
        }
    }

    const onClickChangePassword = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const oldPassword = document.getElementById('old-password').value;
        const newPassword = document.getElementById('new-password-update').value;
        const newPasswordRetype = document.getElementById('retype-password-update').value;
        if (newPassword !== newPasswordRetype) {
            console.debug(`Password inputs do not match`)
            window.alert('Password inputs do not match.\n\nPlease try again.');
            setIsLoading(false);

        } else {
            handleUpdatePassword(oldPassword, newPassword).then(res => {
                window.alert('Password change successful!');
                window.location = `${getPageLocation(Environment, apiStage, 'settings')}`;
                // window.sessionStorage.clear();
                // window.localStorage.clear();
                setIsLoading(false);

            }).catch(error => {
                handleError(
                    'pages.change-password.onClickChangePassword.handleUpdatePassword',
                    error,
                    true,
                    'An error occurred while attempting to update your password.\n\nPlease try again later.',
                    'Caught error in onClickChangePassword.handleUpdatePassword'
                );
                setIsLoading(false);

            });
        }
    };

    const onClickCancelPasswordChange = (event) => {
        event.preventDefault();
        window.location = `${getPageLocation(Environment, apiStage, 'settings')}`;
    };

    const ChangePasswordView = () => {
        return (
            <div className={'reset-view'}>
                <div className={'header-div'}>
                    <h2>Update your password</h2>
                </div>
                <div>
                    <form>
                        <span>
                            <div className={'input-div'}>
                                <label htmlFor={'old-password'}>Old Password:</label>
                                <input
                                    id={'old-password'}
                                    placeholder={''}
                                    autoComplete={'new-password'}
                                    type={'password'}
                                    required={true}
                                />
                            </div>
                            <div className={'input-div'}>
                                <label htmlFor={'new-password-update'}>New Password:</label>
                                <input
                                    id={'new-password-update'}
                                    placeholder={'new password'}
                                    autoComplete={'new-password'}
                                    type={'password'}
                                    required={true}
                                />
                            </div>
                            <div className={'input-div'}>
                                <label htmlFor={'retype-password-update'}>Retype New Password:</label>
                                <input
                                    id={'retype-password-update'}
                                    placeholder={'retype new password'}
                                    autoComplete={'new-password'}
                                    type={'password'}
                                    required={true}
                                />
                            </div>
                            <div className={'button-div'}>
                                <button
                                    disabled={false}
                                    type={"submit"}
                                    onMouseOver={e => (e.currentTarget.style.backgroundColor = '#25dad4')}
                                    onMouseOut={e => (e.currentTarget.style.backgroundColor = '#1ebdb7')}
                                    onClick={e => onClickChangePassword(e)}
                                >
                                    Change Password
                                </button>
                            </div>
                            <div className={'button-div-cancel'}>
                                <button
                                    disabled={false}
                                    type={"submit"}
                                    onMouseOver={e => (e.currentTarget.style.backgroundColor = '#f55353')}
                                    onMouseOut={e => (e.currentTarget.style.backgroundColor = '#eb3b3b')}
                                    onClick={e => onClickCancelPasswordChange(e)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </span>
                    </form>
                </div>
            </div>
        );
    };

    const View = () => {
        if (isLoading) {
            return <LoadingGIF message={'Loading...'} />;

        } else {
            return <ChangePasswordView />;

        }
    };

    return (
        <div className={'sign-up-splash'}>
            <Container fluid={true} style={{cursor: 'default'}}>
                <Row xs={1} className={'row-1'}>
                    <Col className={'sign-up-col'}>
                        <div className={'splash-inner'}>
                            <div className={'new-membership-body'}>
                                <View />
                            </div>
                            <Footer />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );


};

export default withAuthenticator(ChangePassword);