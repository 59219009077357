// https://docs.aws.amazon.com/prescriptive-guidance/latest/patterns/authenticate-react-application-users-by-using-amazon-cognito-and-aws-amplify.html
// https://stackoverflow.com/questions/77667962/aws-amplify-authentication-issue-auth-userpool-not-configured-in-a-next-js-pr
// https://docs.amplify.aws/javascript/build-a-backend/auth/manage-user-session/

// import {ResourcesConfig} from "aws-amplify";

export const config = {
    Auth: {
        Cognito: {
            userPoolClientId: "ibfkddg2mfaop3mm1n4h2rbad",
            userPoolId: "us-east-1_dnDcy3gwj",
            region: "us-east-1",
            mandatorySignIn: false,
            authenticationFlowType: "USER_PASSWORD_AUTH",
            loginWith: {
                oauth: {
                    domain: "https://simplybooks.auth.us-east-1.amazoncognito.com",
                    scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                    redirectSignIn: ["http://localhost:3000", "https://simplybooks.io/test/dashboard/index.html", "https://simplybooks.io", "https://simplybooks.io/test"],
                    redirectSignOut: ["http://localhost:3000", "https://simplybooks.io/test/dashboard/index.html", "https://simplybooks.io", "https://simplybooks.io/test"],
                    responseType: "code" // or token
                }
            }
        }
    }
};

// export const awsConfiguration: ResourcesConfig = {
//     Auth: {
//         Cognito: {
//             userPoolClientId: "ibfkddg2mfaop3mm1n4h2rbad",
//             userPoolId: "us-east-1_dnDcy3gwj",
//             region: "us-east-1",
//             mandatorySignIn: false,
//             authenticationFlowType: "USER_PASSWORD_AUTH",
//             loginWith: {
//                 oauth: {
//                     domain: "https://simplybooks.auth.us-east-1.amazoncognito.com",
//                     scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
//                     redirectSignIn: ["localhost:3000", "http://localhost:3000", "https://simplybooks.io"],
//                     redirectSignOut: ["localhost:3000", "http://localhost:3000", "https://simplybooks.io"],
//                     responseType: "code" // or token
//                 }
//             }
//         }
//     }
// }

