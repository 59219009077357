/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import { autoSignIn, confirmSignUp, signUp } from "aws-amplify/auth";
import { objectDebug } from "../utils/exception-handling";
import { handleError } from "../utils/exception-handling";
import { isEmpty } from "../utils/objectTools";

export async function handleSignUp (username, password, phoneNumber, setIsLoading) {
    try {
        setIsLoading(true);
        const res = await signUp({ username, password, options: { autoSignIn: true } });
        objectDebug('components.membership.SignUpForm.handleSignUp', res);
        setIsLoading(false);
        return res;
    } catch (error) {
        handleError('components.membership.SignUpForm.handleSignUp', error, false, '', '', null);

    }
}

export async function handleAutoSignIn() {
    try {
        const signInOutput = await autoSignIn();
        objectDebug('components.membership.functions.handleAutoSignIn', signInOutput);
        return {status_: true};
    } catch (error) {
        handleError('components.membership.functions.handleAutoSignIn', error, false, '', '', null);
        return {status_: false};
    }
}

export async function handleSignUpConfirmation(username, confirmationCode) {
    try {
        const res = await confirmSignUp({username, confirmationCode});
        objectDebug('components.membership.functions.handleSignUpConfirmation.confirmSignUp (res)', res)
        if (res.isSignUpComplete) {
            Object.assign(res, {error: null, status_: true});
            return res;

        } else {
            console.debug(res)
            throw new Error('Failed to complete signUp');

        }
    } catch (error) {
        handleError('components.membership.functions.handleSignUpConfirmation', error, false, '', '', null);
        return {error: "handleSignUpConfirmation Error", status_: false}
    }
}

export class PasswordValidationResult {
    constructor(number, uppercase, lowercase, special, len, empty) {
        this.number = number;
        this.uppercase = uppercase;
        this.lowercase = lowercase;
        this.special = special;
        this.len = len;
        this.empty = empty;
    }
}

export function validatePasswordContents (password) {
    try {
        const empty = isEmpty(password);
        const number = /[0-9]/.test(password);
        const uppercase = /[A-Z]/.test(password);
        const lowercase = /[a-z]/.test(password);
        const special = /[^A-Za-z0-9]/.test(password);
        const length = password.length >= 8;
        return new PasswordValidationResult(number, uppercase, lowercase, special, length, empty);
    } catch (error) {
        console.debug(`ValidatePasswordContents Error`)
        console.warn(error?.message || error);
        return new PasswordValidationResult(false, false, false, false, false, false);
    }
}