/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React, {useState} from "react";
import axios from "axios";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { getAndDebug, handleError, objectDebug } from "../../utils/exception-handling";
import { isEmpty } from "../../utils/objectTools";
import { getMainServerEndpoint } from "../../utils/endpoints";
import { apiStage, Environment } from "../../utils/constants";
import { FormGroup, Input, Label } from "reactstrap";
import './sign-up-form.scss';
import { handleSignUp, validatePasswordContents } from "../../functions/functions";
import { getPageLocation } from "../../utils/getPageLocation";


// https://docs.stripe.com/payments/finalize-payments-on-the-server?platform=web&type=setup
// https://docs.stripe.com/payments/payment-element/migration?integration-path=future

const source = "components/sign-up/signup-form.js";
const component = "PaymentForm";

const PaymentForm = ({setIsLoading, setSignUpConfirmationRequired, setConfirmationDest}) => {
    const [messages, _setMessages] = useState('');
    const setMessage = (message) => {
        _setMessages(`${messages}\n\n${message}`);
    };
    const stripe = useStripe();
    const elements = useElements();

    if (!stripe || !elements) {
        // Stripe has not loaded yet
        return;
    }

    class validationResponse {
        constructor(is_empty, is_valid) {
            this.is_empty = is_empty;
            this.is_valid = is_valid;
        };
    }

    async function validate_promo_code (promo_code) {
        try {
            if (isEmpty(promo_code)) {
                return new validationResponse(true, false);

            } else {
                const validatePromoUri = `${getMainServerEndpoint(Environment, apiStage)}/simplybooks/validate_promo_code`;
                const promoValidationPayload = {
                    'promo': promo_code
                };
                let validationRes = await axios.post(validatePromoUri, promoValidationPayload, {headers: {'Content-Type': 'application/json'}});
                objectDebug('Validate Promo Code Response', validationRes);
                const isValidPromoCode = validationRes.data['data']['is_valid'];
                console.debug(`[isValidPromoCode] ::: ${isValidPromoCode}`)
                if (isValidPromoCode) {
                    return new validationResponse(false, true);

                } else {
                    return new validationResponse(false, false);

                }
            }
        } catch (error) {
            handleError(
                `[${source}][${component}] validate_promo_code()`,
                error,
                false,
                'An error occurred while attempting to validate your Promo Code.\n\nPlease try again.',
                'Caught error from validate_promo_code() function.',
                null
            );
            return new validationResponse(false, false);
        }
    }

    const onClickSignUp = (e) => {
        e.preventDefault();
        try {
            console.debug('Pulling DOM element values...')
            const username = getAndDebug(document.querySelector('#email').value.toUpperCase(), 'pages.membership.Membership.onClickSignUp (username)');
            const promo = getAndDebug(document.querySelector('#promo').value, 'pages.membership.Membership.onClickSignUp (promo)');
            const password = getAndDebug(document.querySelector('#new-password').value, 'pages.membership.Membership.onClickSignUp (password)');
            const passwordConf = getAndDebug(document.querySelector('#new-password-conf').value, 'pages.membership.Membership.onClickSignUp (password-conf)');
            const name = getAndDebug(document.querySelector('#name-on-card').value, 'pages.membership.Membership.onClickSignUp (name)');
            const passwordValidation = validatePasswordContents(password);
            if (passwordValidation.empty) {
                window.alert('Password provided does not contain any characters.\n\nPlease try again.');
            }
            else if (!passwordValidation.lowercase) {
                window.alert('Password must contain at least one lowercase letter.\n\nPlease try again.');
            } else if (!passwordValidation.uppercase) {
                window.alert('Password must contain at least one uppercase letter.\n\nPlease try again.');
            } else if (!passwordValidation.number) {
                window.alert('Password must contain at least one number.\n\nPlease try again.');
            } else if (!passwordValidation.special) {
                window.alert('Password must contain at least one special character.\n\nPlease try again.');
            } else if (!passwordValidation.len) {
                window.alert('Password must be at least 8 characters long.\n\nPlease try again.');
            } else {
                elements.submit().then(submission => {
                    if (submission.error) {
                        console.debug(`elements.submit() error`)
                        setMessage(submission.error.message);

                    } else {
                        objectDebug('[element submission]', submission);
                        // setIsLoading(true);  // **** THESE ARE THE REASON THAT THE CARD ELEMENT WASN'T ABLE TO BE FOUND ! CHANGING THE STATE UNMOUNTS THE ELEMENT.
                        // setFormProcessing(true);  // **** THESE ARE THE REASON THAT THE CARD ELEMENT WASN'T ABLE TO BE FOUND ! CHANGING THE STATE UNMOUNTS THE ELEMENT.

                        if (isEmpty(username) || isEmpty(password) || isEmpty(passwordConf) || isEmpty(name)) {
                            if (isEmpty(username)) {
                                document.querySelector('#email').style.backgroundColor = '#eb3b3b';
                            } else if (isEmpty(password)) {
                                document.querySelector('#new-password').style.backgroundColor = '#eb3b3b';
                            } else if (isEmpty(passwordConf)) {
                                document.querySelector('#new-password-conf').style.backgroundColor = '#eb3b3b';
                            } else if (isEmpty(name)) {
                                document.querySelector('#name-on-card').style.backgroundColor = '#eb3b3b';
                            }
                            window.alert('Please fill out the required fields and re-submit your request.');
                            setIsLoading(false);

                        } else if (password !== passwordConf) {
                            window.alert(`The password you entered does not match your confirmation.\n\nPlease try again.`);
                            setIsLoading(false);

                        } else {
                            let domain_confirmation = false;
                            if (!username.toLowerCase().includes('gmail.com')) {
                                if (window.confirm('Simplybooks.io requires a Google account to work correctly, and we see that you are using a non-gmail address to create your account.\n\nThis will not necessarily affect your account, but later steps will require a valid Google account to link to.\n\nAre you sure you want to continue?')) {
                                    domain_confirmation = true;
                                }
                            } else {
                                domain_confirmation = true;
                            }

                            if (domain_confirmation) {

                                // window.alert("Please do not refresh the page while we are confirming your registration.\n\nPress OK to continue.")
                                // Creating Confirmation Token of transaction ....
                                // https://docs.stripe.com/payments/finalize-payments-on-the-server
                                stripe.createConfirmationToken({
                                    elements,
                                    params: {
                                        billing_details: {name: name, email: username}
                                    }
                                }).then(conf => {
                                    setIsLoading(true);
                                    objectDebug('pages.membership.Membership.onClickSignUp.createConfirmationToken (conf)', conf);
                                    if (conf.error) {
                                        setMessage(conf.error.message);
                                        setIsLoading(false);

                                    } else {
                                        validate_promo_code(promo).then((promoResponse) => {
                                            objectDebug(`validate_promo_code().then() Response ==>`, promoResponse)
                                            if (!promoResponse.is_empty && !promoResponse.is_valid) {
                                                window.alert("You provided a promo code, but we were not able to verify it.\n\nPlease check your promo code or remove it to continue.");
                                                setIsLoading(false);

                                            } else if (promoResponse.is_empty || promoResponse.is_valid) {
                                                const createCustomerUri = `${getMainServerEndpoint(Environment, apiStage)}/simplybooks/create_customer`;
                                                const createCustomerPayload = {
                                                    'name': name,
                                                    'email': username,
                                                    'promo_code': promo,
                                                    'membership_code': window.sessionStorage.getItem('selection'),
                                                    'confirmation_code': conf.confirmationToken.id,
                                                    'referral_id': window.sessionStorage.getItem('referral_id')
                                                }
                                                axios.post(createCustomerUri, createCustomerPayload, {headers: {'Content-Type': 'application/json'}}).then(createRes => {
                                                    objectDebug('Create Customer Response', createRes.data);
                                                    if (createRes.status !== 200) {
                                                        window.alert('An error occurred while attempting to create a customer profile for you.\n\nPlease refresh your browser and try again.');

                                                    } else {
                                                        const customerResData = createRes.data;
                                                        // const customer_id = customerResData['data']['customer_id'];
                                                        const existingSubscription = customerResData['data']['has_active_subscription'];

                                                        if (existingSubscription) {
                                                            window.alert('You already have an active SimplyBooks.io subscription!\n\nPlease sign-in to continue.');

                                                        } else {

                                                            handleSignUp(username, password, '', setIsLoading).then(signUpRes => {
                                                                window.localStorage.setItem('user-id', signUpRes.userId);
                                                                if (signUpRes.nextStep.signUpStep === "CONFIRM_SIGN_UP") {
                                                                    setSignUpConfirmationRequired(true);
                                                                    window.localStorage.setItem('email', username);
                                                                    window.localStorage.setItem('promo', promo);
                                                                    setConfirmationDest(signUpRes.nextStep.codeDeliveryDetails.destination);

                                                                    if (signUpRes.nextStep.codeDeliveryDetails.deliveryMedium === "EMAIL") {
                                                                        window.alert(`A confirmation email has been sent to ${signUpRes.nextStep.codeDeliveryDetails.destination}\n\n Please enter the code to complete your sign up with SimplyBooks.`);
                                                                        setIsLoading(false);

                                                                    } else {
                                                                        throw new Error(`Uncaught scenario in signup confirmation`);

                                                                    }
                                                                } else if (signUpRes.nextStep.signUpStep === "DONE" || signUpRes.isSignUpComplete) {
                                                                    /////////////
                                                                    ///////////// THIS SHOULD NEVER HAPPEN
                                                                    /////////////
                                                                    window.alert(`Your account has been created!\n\nPlease sign-in to continue.`);
                                                                    setIsLoading(false);
                                                                    window.location = `${getPageLocation(Environment, apiStage, '/')}`;

                                                                } else {
                                                                    console.debug(`res.nextStep: ${signUpRes.nextStep}`)
                                                                    console.debug(`res.isSignUpComplete: ${signUpRes.isSignUpComplete}`)
                                                                    console.debug(`res.userId: ${signUpRes.userId}`)
                                                                    throw new Error(`uncaught scenario in pages.membership.Membership.onClickSignUp.handleSignUp`);
                                                                }
                                                            }).catch(error => {
                                                                handleError(
                                                                    `[${source}][${component}] onClickSignUp > handleSignUp`,
                                                                    error,
                                                                    true,
                                                                    'A signup error occurred.\n\nPlease check the length of your password and try again.',
                                                                    error.message,
                                                                    null
                                                                );
                                                                setIsLoading(false);
                                                            });
                                                        }
                                                    }
                                                }).catch(error => {
                                                    handleError(
                                                        `[${source}][${component}] onClickSignUp > create_customer endpoint`,
                                                        error,
                                                        true,
                                                        'An error occurred while attempting to create a customer profile for you.\n\nPlease refresh your browser and try again.',
                                                        'Caught error from handleSignUp: create customer endpoint',
                                                        null
                                                    );
                                                    setIsLoading(false);
                                                });
                                            }
                                        }).catch(error => {
                                            handleError(
                                                `[${source}][${component}] onClickSignUp > promo code validation endpoint`,
                                                error,
                                                true,
                                                'An error occurred while attempting to validate your Promo Code.\n\nPlease try again.',
                                                'Caught error from handleSignUp: promo code validation endpoint',
                                                null
                                            );
                                            setIsLoading(false);
                                        });
                                    }
                                }).catch(error => {
                                    handleError(
                                        `[${source}][${component}] onClickSignUp > stripe.createConfirmationToken`,
                                        error,
                                        true,
                                        'An error occurred while attempting to create a customer profile for you.\n\nPlease refresh your browser and try again.',
                                        'Caught error from handleSignUp: stripe.createConfirmationToken',
                                        null
                                    );
                                    setIsLoading(false);

                                });
                            }
                        }
                    }
                }).catch(error => {
                    console.debug(`Error submitting payment element`)
                    handleError(
                        `[${source}][${component}] onClickSignUp > elements.submit`,
                        error,
                        true,
                        'An error occurred while attempting to create your account.\n\nPlease refresh your browser and try again.',
                        'Caught error from handleSignUp: elements.submit',
                        null
                    );
                    setIsLoading(false);
                });
            }

        } catch (error) {
            handleError(
                `[${source}][${component}] onClickSignUp > OverArching Exception`,
                error,
                true,
                'A Sign-Up Error occurred.\n\nPlease refresh your browser and try again.',
                '',
                null
            );
            setIsLoading(false);
        }
    };

    return (
        <form className={'sign-up-form-div'}>
            <span className={'card-name-span'}>
                <Input type={'text'} required={true} placeholder={'Name on card'} id={'name-on-card'} className={'name-on-card'}/>
            </span>
            <div className={'stripe-element-div'}>
                <PaymentElement />
            </div>
            <hr />
            <span className={'aws-inputs'}>
                <FormGroup className={'aws-form-group'}>
                    <Input autoComplete={'new-password'} type={'text'} required={false} placeholder={'Promo-code'} id={'promo'} className={'promo'}/>
                    <Input type={'email'} required={true} placeholder={'Email'} id={'email'} className={'email'} />
                    <Label for={'new-password'}>Password:</Label>
                    <Input autoComplete={'new-password'} type={'password'} required={true} placeholder={''} id={'new-password'} className={'new-password'} />
                    <Label for={'new-password-conf'}>Confirm Password:</Label>
                    <Input autoComplete={'new-password'} type={'password'} required={true} placeholder={''} id={'new-password-conf'} className={'new-password-conf'} />
                </FormGroup>
            </span>
            <span className={'password-note'}>
                <p >** Password must adhere to the following:</p>
                <ul id={'pw-requirements'}>
                    <li>at least 8 characters long</li>
                    <li>1 special character (i.e. !, @, #, $, %, ^, &, *)</li>
                    <li>1 lowercase letter</li>
                    <li>1 uppercase letter</li>
                </ul>
            </span>
            <span className={'submission'}>
                <button
                    disabled={!stripe || !elements}
                    type={'submit'}
                    onMouseOver={e => (e.currentTarget.style.backgroundColor = '#25dad4')}
                    onMouseOut={e => (e.currentTarget.style.backgroundColor = '#1ebdb7')}
                    onClick={e => onClickSignUp(e)}
                >
                    {!stripe || !elements ? 'Loading...' : 'Create Account'}
                </button>
                <span>
                  <div>{messages}</div>
                </span>
            </span>
        </form>
    );

};

export default PaymentForm;