/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React from "react";
import './page.scss';
import Footer from "../components/footer/footer";
import { Container, Row, Col } from 'reactstrap';

const GooglePrivacy = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const Body = () => {
        return (
            <div className={'google-disclosure'}>
                <h3>Disclosure</h3>
                <p>
                    SimplyBooks.io's use and transfer to any other app of information received from Google APIs will adhere to Google API Services User Data Policy
                    which can be found <a style={{color: "blue"}} href={'https://developers.google.com/terms/api-services-user-data-policy'}>here</a>.
                </p>
                <br />
                <h3>Explanation of Google Permissions and how SimplyBooks.io uses them</h3>
                <p>
                    SimplyBooks.io requires the use of a small number of permissions deemed "non-sensitive" by Google in order to create and manage user accounts, as well
                    as provide in-app functionality critical to providing end-user services:
                </p>
                <ul>
                    <li>
                        <strong>See your primary Google Account email address: </strong>
                        <ul>
                            <li>Used for the authentication of users, as well as the creation and management of accounts for users that sign up with Google.</li>
                        </ul>

                    </li>
                    <li>
                        <strong>See your personal info, including any personal information you've made publicly available: </strong>
                        <ul>
                            <li>Used for the authentication of users, as well as the creation and management of accounts for users that sign up with Google.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Associate you with your personal info on Google: </strong>
                        <ul>
                            <li>Used for the authentication of users, as well as the creation and management of accounts for users that sign up with Google.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>See, edit, create and delete <u>only</u> the specific Google Drive files you use with this app: </strong>
                        <ul>
                            <li>Used for the creation and management of Google Sheets created as a result of linking to your bank and synchronizing your transaction history.</li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    return (
        <div className={'google-disclosure-splash'}>
            <Container fluid={true} style={{cursor: 'default'}}>
                <Row xs={1} className={'row-1'}>
                    <Col>
                        <div className={'splash-inner'}>
                            <header>
                                <h2><strong>Google Limited Use Disclosure</strong></h2>
                                <h5><i>Disclosure about what permissions we use from Google and what we do with them.</i></h5>
                            </header>
                            <div className={'page-body'}>
                                <Body />
                            </div>
                            <Footer />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );


};

export default GooglePrivacy;