/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React, { useState, useEffect } from "react";
import './page.scss';
import Footer from "../components/footer/footer";
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { getPageLocation } from "../utils/getPageLocation";
import { apiStage, Environment } from "../utils/constants";
import { handleError } from "../utils/exception-handling";
import { getAndDebug, objectDebug } from "../utils/exception-handling";
import { handleSignUpConfirmation } from "../functions/functions";
import Selection from "../components/sign-up/selection";
import PaymentForm from "../components/sign-up/sign-up-form";
import LoadingGIF from "../components/GIFS/loading";

const SignUp = ({ isAuthenticated, isSigningOut }) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const [isSignedUp, setIsSignedUp] = useState(isAuthenticated);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmationDest, setConfirmationDest] = useState('*****');
    const [signUpConfirmationRequired, setSignUpConfirmationRequired] = useState(false);

    const onClickConfirmSignUp = (e) => {
        e.preventDefault();
        const user_id = window.localStorage.getItem('user-id');
        const code = getAndDebug(document.getElementById('conf-code').value, 'components.membership.functions.onClickConfirmSignUp code');
        setIsLoading(true);
        handleSignUpConfirmation(user_id, code).then(res => {
            objectDebug('components.membership.functions.onClickConfirmSignUp.handleSignUpConfirmation.res', res);

            if (!res.status_) {
                window.alert(`An error occurred while confirming your registration.\n\nPlease try again.`)
                setIsLoading(false);

            } else {
                window.alert(`Your account has been created!\n\nPlease sign-in to continue.`);
                setSignUpConfirmationRequired(false);
                setIsLoading(false);
                window.location = `${getPageLocation(Environment, apiStage, '/')}`;
            }

        }).catch(error => {
            handleError('components.membership.functions.onClickConfirmSignUp', error, false, '', '', null);
            setIsLoading(false);
        });
    };

    const NewMembershipView = () => {
        return (
            <div>
                <Selection />
                <br />
                <div className={'new-account-form'}>
                    <div className={'new-account-form-header'}>
                        <span>
                            <h2>SimplyBooks Account Sign-Up</h2>
                        </span>
                    </div>
                    <div className={'new-account-form-container'}>
                        <PaymentForm
                            setIsLoading={setIsLoading}
                            setSignUpConfirmationRequired={setSignUpConfirmationRequired}
                            setConfirmationDest={setConfirmationDest}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const ConfirmSignUpView = () => {
        return (
            <div className={'signup-confirmation-div'}>
                <h3 className={'signup-conf-header'}>Enter the code sent to {confirmationDest} to complete your sign up.</h3>
                <div className={'signup-conf-body'}>
                    <form onSubmit={e => {onClickConfirmSignUp(e)}}>
                        <div className={'div-1'}>
                            <FormGroup className={'confirmation-inputs'}>
                                <Label for={'conf-code'} className={'conf-code-label'}>Password:</Label>
                                <Input type={'text'} required={true} placeholder={''} id={'conf-code'} className={'conf-code'} />
                            </FormGroup>
                        </div>
                        <div className={'div-2'}>
                            <button
                                disabled={false}
                                type={'submit'}
                                onMouseOver={e => (e.currentTarget.style.backgroundColor = '#25dad4')}
                                onMouseOut={e => (e.currentTarget.style.backgroundColor = '#1ebdb7')}
                            >
                                Confirm Sign Up
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    };

    if (isLoading) {
        return (
            <div className={'sign-up-splash'}>
                <Container fluid={true} style={{cursor: 'default'}}>
                    <Row xs={1} className={'row-1'}>
                        <Col className={'sign-up-col'}>
                            <div className={'splash-inner'}>
                                <div className={'new-membership-body'}>
                                    <LoadingGIF message={'Loading...'}/>
                                </div>
                                <Footer />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );

    } else if (signUpConfirmationRequired) {
        return (
            <div className={'sign-up-splash'}>
                <Container fluid={true} style={{cursor: 'default'}}>
                    <Row xs={1} className={'row-1'}>
                        <Col className={'sign-up-col'}>
                            <div className={'splash-inner'}>
                                <div className={'new-membership-body'}>
                                    <ConfirmSignUpView />
                                </div>
                                <Footer />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );

    } else {
        return (
            <div className={'sign-up-splash'}>
                <Container fluid={true} style={{cursor: 'default'}}>
                    <Row xs={1} className={'row-1'}>
                        <Col className={'sign-up-col'}>
                            <div className={'splash-inner'}>
                                <div className={'new-membership-body'}>
                                    <NewMembershipView />
                                </div>
                                <Footer />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
};

export default SignUp;