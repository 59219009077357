/*Copyright 2024, Blake Donahoo, All rights reserved.*/
export function getBaseSiteUrl(environment, api_stage) {
    if (environment === "local") {
        return "http://localhost:3000";

    } else {
        if (api_stage === "prod") {
            return "https://simplybooks.io";

        } else if (api_stage === "test") {
            // return "https://simply-books-test.s3.amazonaws.com";
            return "https://test.d187zztuhiuluk.amplifyapp.com";

        }
        else {
            return `https://simplybooks.io/${api_stage}`;
        }
    }
}

export function getHome(environment, api_stage) {
    let home = "/";
    // if (api_stage === "test" || api_stage === "dev") {
    //     home = "/index.html";
    // }
    return home;
}

export function getPageLocation(environment, api_stage, destination) {
    const baseUrl = getBaseSiteUrl(environment, api_stage);
    switch(destination){
        case "/":
            return `${baseUrl}${getHome(environment, api_stage)}`;
        default:
            return `${baseUrl}/#/${destination}`;
    }
}
