/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React, { useState, useEffect } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';
import { useGoogleLogin } from "@react-oauth/google";
import GoogleButton from 'react-google-button';
import axios from "axios";
import { PlaidLink } from "react-plaid-link";
import './page.scss';
import { getUserSession } from "../utils/session";
import { getMainServerEndpoint } from "../utils/endpoints";
import { getBaseSiteUrl } from "../utils/getPageLocation";
import { Environment, apiStage } from "../utils/constants";
import { getPageLocation } from "../utils/getPageLocation";
import { isEmpty} from "../utils/objectTools";
import { getAndDebug, handleError, objectDebug } from "../utils/exception-handling";
import { statusCall } from "../utils/status-call";
import LoadingGIF from "../components/GIFS/loading";
import ConnectionTable from "../components/connection-table/connection-table";
import ConnectionWizard from "../components/connection-wizard/connection-wizard";
import ImportDataInstructions from "../components/options/import-data-instructions";
import Footer from "../components/footer/footer";
import {
    Container, Row, Col,
    Card, CardBody, CardTitle,
    UncontrolledTooltip,
    Dropdown, DropdownMenu, DropdownItem, DropdownToggle,
    ButtonToolbar, ButtonGroup, FormGroup, Input,
    Spinner
} from "reactstrap";
import sheet from '../assets/sheet.png';

const source = "pages/connect.js";
const element = "Connect";

const Connect = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState('Loading...');
    const [accountData, setAccountData] = useState([]);
    const [specificAccounts, setSpecificAccounts] = useState([]);
    const [linkToken, setLinkToken] = useState("");
    const [isEligible, setIsEligible] = useState(false);
    const [requiresPlaidReconnect, setReqPlaidReconnect] = useState(false);
    const [disconnectedPlaidItem, setDisPlaidItem] = useState('');
    const [hasConnections, setHasConnections] = useState(false);
    const [showWizard, setShowWizard] = useState(false);
    const [stepNumber, setStepNumber] = useState(1);
    const [googleAuthStatus, setGoogleAuthStatus] = useState(false);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [companyScope, setCompanyScope] = useState("");
    const [sheetLink, setSheetLink] = useState("");
    const [justConnected, setJustConnected] = useState(false);
    const [fetchingNewAccounts, setFetchingNewAccounts] = useState(false);
    const [reRender, setReRender] = useState(false);
    const [showSheetMenu, toggleSheetMenu] = useState(true);
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [showImportData, setShowImportData] = useState(false);
    const [reportIssue, setReportIssue] = useState(false);
    const [hasTrainingJob, setHasTrainingJob] = useState(false);
    const [maxNewConnectionFetchAttempts, setMaxNewConnectionFetchAttempts] = useState(3);
    const [currentFetchAttempt, setCurrentFetchAttempt] = useState(0);

    try {
        if (isEmpty(window.sessionStorage.getItem('year-selection'))) {
            window.sessionStorage.setItem('year-selection', 'This year')
        }
        if (isEmpty(window.sessionStorage.getItem('suppress-training-notification'))) {
            window.sessionStorage.setItem('suppress-training-notification', '0');
        }
        if (isEmpty(window.sessionStorage.getItem('just-connected'))) {
            window.sessionStorage.setItem('just-connected', "0");
        }
    } catch (error) {
        console.warn(error?.message || error);
    }

    function updateMainState (status = {}) {
        try {
            setAccountData(status.accounts);
            setSpecificAccounts(status.filtered_accounts);

            setReqPlaidReconnect(status.requires_reconnect);
            setDisPlaidItem(status.target_disconnect_item);

            setCompanyOptions(status.companies);
            setGoogleAuthStatus(status.google_auth_status);
            setStepNumber(status.step_number);
            setSheetLink(status.sheet_link);
            setHasTrainingJob(status.has_training_job);
            setHasConnections(status.has_connections);

            setLinkToken(status.link_token);
            window.sessionStorage.setItem('link_token', `${status.link_token}`);
            setIsEligible(status.is_eligible);

            setMaxNewConnectionFetchAttempts(status.max_fetch_attempts);
        } catch (error) {
            console.debug(`[${source}][${element}] Failed to update main state!`)
            console.error(error);
        }
    }

    useEffect( () => {
        try {
            const jc_session = window.sessionStorage.getItem('just-connected');
            if (!justConnected && jc_session === "0") {
                console.debug('FIRED main useEffect')
                getUserSession().then(session => {
                    setIsLoading(true);
                    statusCall(session, source, element).then(status => {
                        objectDebug('useEffect statusCall response', status);
                        if (status.error) {
                            window.alert(status.error_display_msg);

                        } else {
                            updateMainState(status);
                        }
                        setIsLoading(false);

                    }).catch(error => {
                        console.warn(error?.message);
                        console.error(error);
                        setIsLoading(false);
                    })

                }).catch(error => {
                    console.debug(`[${source}][${element}] useEffect.getUserSession() error`)
                    console.error(error)
                    window.localStorage.clear();
                    window.sessionStorage.clear();
                    window.alert(`Your session has expired, please log in again to continue`);
                    setIsLoading(false);
                    window.location = `${getPageLocation(Environment, apiStage, '/')}`;

                });
            }

        } catch (error) {
            console.debug(`[${source}][${element}] Main useEffect Error`)
            console.error(error)
            setIsLoading(false);
        }

    }, [companyScope, reRender]);



    useEffect(() => {
        try {
            if (justConnected) {
                console.debug(`FIRED justConnected useEffect`)

                const interval = setInterval(() => {
                    // const fetchAttempt = parseInt(window.sessionStorage.getItem('fetch-attempt'));
                    console.debug(`ATTEMPTING justConnected useEffect --> ${currentFetchAttempt}`)
                    if (currentFetchAttempt === maxNewConnectionFetchAttempts) {
                        console.debug(`Hit maximum number of maxNewConnectionFetchAttempts: ${maxNewConnectionFetchAttempts}`);
                        window.alert("It's taking a bit longer than normal to get all of your transactions from your bank. Please refresh the page and wait a few minutes for your connection to show up in SimplyBooks.");
                        window.location.reload();

                    } else {
                        const company_scope = window.sessionStorage.getItem("new_company");
                        window.sessionStorage.setItem("company_scope", company_scope);
                        getUserSession().then(session => {
                            if (currentFetchAttempt === 0) {
                                setFetchingNewAccounts(true);
                                setIsLoading(true);
                                setLoadingMessage('Fetching your account data...');

                            } else if (currentFetchAttempt === 1 && currentFetchAttempt < maxNewConnectionFetchAttempts) {
                                setLoadingMessage('Waiting on your bank...');

                            } else if (currentFetchAttempt === 2 && currentFetchAttempt < maxNewConnectionFetchAttempts) {
                                setLoadingMessage('Building your new Google Sheet...');

                            } else if (currentFetchAttempt === 3 && currentFetchAttempt < maxNewConnectionFetchAttempts) {
                                setLoadingMessage('Synchronizing your transactions...');

                            }
                            statusCall(session, source, element).then(status => {
                                objectDebug('useEffect statusCall response', status);
                                if (status.error) {
                                    window.alert(status.error_display_msg);
                                    window.sessionStorage.setItem('just-connected', "0");
                                    setJustConnected(false);
                                    setFetchingNewAccounts(false);
                                    setIsLoading(false);

                                } else if (status.filtered_accounts.length === 0) {
                                    // window.sessionStorage.setItem('fetch-attempt', `${fetchAttempt+1}`);
                                    setCurrentFetchAttempt(currentFetchAttempt+1)

                                } else {
                                    window.sessionStorage.setItem('just-connected', "0");
                                    setCompanyScope(company_scope);
                                    updateMainState(status);
                                    toggleSheetMenu(false);
                                    setJustConnected(false);
                                    setCurrentFetchAttempt(0);
                                    setFetchingNewAccounts(false);
                                    setIsLoading(false);
                                }


                            }).catch(error => {
                                console.warn(error?.message);
                                console.error(error);
                                window.sessionStorage.setItem('just-connected', "0");
                                setJustConnected(false);
                                setCurrentFetchAttempt(0);
                                setFetchingNewAccounts(false);
                                setIsLoading(false);
                            })

                        }).catch(err => {
                            console.debug(`[${source}][${element}] useEffect.getUserSession() error`)
                            console.error(err)
                            window.localStorage.clear();
                            window.sessionStorage.clear();
                            window.alert(`Your session has expired, please log in again to continue`);
                            setIsLoading(false);
                            window.location = `${getPageLocation(Environment, apiStage, '/')}`;

                        });

                    }

                }, 10000); // every 7 seconds
                return () => clearInterval(interval);
            }

        } catch (error) {
            console.debug(`[${source}][${element}] justConnected useEffect Error`)
            console.error(error)
            window.sessionStorage.setItem('just-connected', "0");
            setJustConnected(false);
            setCurrentFetchAttempt(0);
            setFetchingNewAccounts(false);
            setIsLoading(false);
        }


    }, [justConnected, accountData, reRender]);

    useEffect(() => {
        const suppress = window.sessionStorage.getItem('suppress-training-notification');
        if (hasTrainingJob && isEmpty(companyScope) && suppress === "0") {
            try {
                const interval = setInterval(() => {
                    setReRender(!reRender);
                }, 30000); // every 30 seconds
                return () => clearInterval(interval);
            } catch (error) {
                console.warn(error?.message || error);
                setHasTrainingJob(false);
            }
        }
    }, [hasTrainingJob]);


    const onPlaidSuccess = (plaid_public_token = "", metadata = {}, reconnect = false) => {
        console.log('plaid metadata')
        console.log(metadata)

        console.debug(`onPlaidSuccess: True; public_token: ${plaid_public_token}`)
        let company = isEmpty(getAndDebug(window.sessionStorage.getItem('new_company'), 'new_company')) ? companyScope : window.sessionStorage.getItem('new_company');
        window.sessionStorage.setItem('company_scope', company);
        // if (!isEmpty(company)) {
        //     setCompanyScope(company);
        // } else {
        //     company = companyScope;
        // }
        getUserSession().then(session => {
            setIsLoading(true);
            if (isEmpty(session.userEmail)) throw new Error(`User Session did not contain a valid email: ${session.userEmail}`);

            if (reconnect) {
                let reconnectPayload = {"item_id": `${disconnectedPlaidItem}`};
                const reconnectEndpoint = `${getMainServerEndpoint(Environment, apiStage)}/simplybooks/${session.userEmail}/re_connect_plaid`;
                const reconnectHeaders = {headers: {"Authorization": session.idToken, "Content-Type": "application/json"}};
                axios.post(reconnectEndpoint, reconnectPayload, reconnectHeaders).then(reconnection => {
                    if (reconnection.status !== 200) throw new Error('Failed to process account reconnection.');
                    if (reconnection.status === 200) {
                        window.alert(`Your account connection has been successfully resumed.`);

                    }
                    setIsLoading(false);

                }).catch(error => {
                    handleError(
                        'pages.connect.Connect.onPlaidSuccess [reconnect]',
                        error,
                        true,
                        'An error occurred while attempting to restart your connection.\n\nPlease refresh the page and try again.',
                        'Caught error in onPlaidSuccess [reconnect]'
                    );
                    setIsLoading(false);

                });

            } else {
                const payload = new URLSearchParams();
                const year_selection = window.sessionStorage.getItem('year-selection');
                payload.append('year_selection', year_selection.toLowerCase());
                payload.append("userId", session.userEmail.toUpperCase());
                payload.append("public_token", plaid_public_token);
                payload.append("companyScope", `${company}`);
                const exchangeEndpoint = `${getMainServerEndpoint(Environment, apiStage)}/simplybooks/${session.userEmail}/exchange`;
                const exchangeHeaders = {
                    headers: {
                        "Authorization": session.idToken,
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Accept": "*/*"
                    }
                };
                axios.post(exchangeEndpoint, payload, exchangeHeaders).then((exchangeResponse) => {
                    console.debug(`Exchange response:`)
                    const exchangeData = exchangeResponse.data;
                    console.debug(exchangeData)
                    switch (exchangeResponse.status) {
                        case(200):
                            window.sessionStorage.setItem('just-connected', "1");
                            setShowWizard(false);
                            // window.alert(`Your selected accounts have successfully been linked!\n\nPlease allow a few moments for your new connections to appear in SimplyBooks.`);
                            // setIsLoading(false);
                            setJustConnected(true);
                            break;
                        default:
                            setShowWizard(false);
                            setIsLoading(false);
                            window.alert(`Server Error: Exchange Response Code ${exchangeResponse.status}`);
                            break;
                    }
                }).catch(error => {
                    setShowWizard(false);
                    setIsLoading(false);
                    handleError(
                        'pages.Connect.onPlaidSuccess.getUserSession.axios',
                        error,
                        true,
                        'An error occurred while connecting your account.\n\nPlease try again.',
                        'Caught error in onPlaidSuccess'
                    );
                });
            }

        }).catch(error => {
            handleError(
                'pages.Connect.onPlaidSuccess.getUserSession Error',
                error,
                false,
                '',
                'Caught error in onPlaidSuccess'
            );
            setIsLoading(false);
        });
    };

    const PlaidLinkDiv = ({ link_token = "", button_text = "", reconnect = false }) => {
        // style={{padding: '1rem 1.5rem', borderRadius: '.5rem', fontWeight: 'bold', color: '#ffffff', backgroundColor: '#1fc6d0'}}
        return (
            <PlaidLink
                style={{padding: '1rem 1.5rem', borderRadius: '.5rem', fontWeight: 'bold', color: '#ffffff', backgroundColor: '#1fc6d0'}}
                onSuccess={(public_token, metadata) => onPlaidSuccess(public_token, metadata, reconnect)}
                token={link_token}>
                {button_text}
            </PlaidLink>
        );
    };

    const userRemovedAccountRequest = (accountOb) => {
        try {
            getUserSession().then(session => {
                setIsLoading(true);
                const removeAccountEndpoint = `${getMainServerEndpoint(Environment, apiStage)}/simplybooks/${session.userEmail}/remove_account`;
                const removeAccountHeaders = {headers: {"Authorization": session.idToken, "Content-Type": "application/json"}};
                let removeAccountPayload = {
                    "item_id": accountOb['item_id'],
                    "account_id": accountOb['account_id']
                }
                axios.post(removeAccountEndpoint, removeAccountPayload, removeAccountHeaders).then((removeResponse) => {
                    console.debug(`removeResponse Code: ${removeResponse.status}`)
                    console.debug('removeResponse data')
                    console.debug(removeResponse.data)
                    switch (removeResponse.status) {
                        case(200):
                            window.alert(`Successfully removed account: ${accountOb['display_name']}`);
                            setReRender(!reRender);
                            return null
                        default:
                            window.alert(`An error occurred while attempting to remove account: ${accountOb['display_name']}`);
                            setReRender(!reRender);
                            return accountOb
                    }
                }).catch(error => {
                    console.debug(`pages.Connect.userRemovedAccountRequest.getUserSession.axios Error`)
                    console.error(error)
                    setReRender(!reRender);
                    setIsLoading(false);
                });
            }).catch(error => {
                console.debug(`pages.Connect.userRemovedAccountRequest.getUserSession Error`)
                console.error(error)
                setReRender(!reRender);
                setIsLoading(false);
            });
        } catch (error) {
            console.debug(`pages.Connect.userRemovedAccountRequest Error`)
            console.error(error)
            setReRender(!reRender);
            setIsLoading(false);
        }
    };

    const onClickRemove = (event, accountIndex) => {
        event.preventDefault();
        console.debug(`pages.Connect.onClickRemove.accountIndex: ${accountIndex}`)
        const targetAccount = specificAccounts[accountIndex];
        objectDebug('pages.Connect.onClickRemove.targetAccount', targetAccount)
        let confirmationMessage = `Are you sure you want to remove this account?\n${targetAccount['display_name']}\n\nThis will not remove the page from your sheet, but we will stop listening for updates for this particular account.`;
        if (specificAccounts.length === 1) {
            confirmationMessage = `Are you sure you want to remove this account?\n${targetAccount['display_name']}\n\nThis is the last account connected to this bank, removing it will remove SimplyBooks' connection and allow you to connect to a different bank.\n\nThis will not delete this specific company, so connections made after this will feed into the same Google Sheet.`;
        }
        if (window.confirm(confirmationMessage)) {
            try {
                const updatedAccountData = specificAccounts.map((accountOb, accountIdx) => {
                    if (accountIdx === accountIndex) {
                        return userRemovedAccountRequest(accountOb);
                    } else {
                        return accountOb;
                    }
                })
                const filtered = updatedAccountData.filter(function (element) {
                    return element !== undefined;
                });
            } catch (error) {
                console.debug(`pages.Connect.onClickRemove Error`)
                console.error(error)
            }
        } else {
            console.debug(`Aborted account removal for ${targetAccount['display_name']}`)
        }
    };

    const GoogleAuthSuccess = (response) => {
        // https://www.dhiwise.com/post/react-google-oauth-the-key-to-secure-and-quick-logins
        // https://medium.com/@sahadmuhammed289/react-js-a-step-by-step-guide-to-google-authentication-926d0d85edbd
        // https://www.npmjs.com/package/@react-oauth/google
        // https://developers.google.com/identity/protocols/oauth2/web-server#exchange-authorization-code
        // https://developers.google.com/identity/protocols/oauth2/native-app#chrome
        console.debug(`pages.Connect.GoogleAuthSuccess:`)
        console.log(response)
        const authCode = response.code;
        const payload = new URLSearchParams();
        payload.append("auth_code", response.code);
        payload.append("scope", response.scope);
        payload.append('redirect_uri', `${getBaseSiteUrl(Environment, apiStage)}`)
        console.debug(`authCode: ${authCode}`)
        try {
            getUserSession().then(session => {
                setIsLoading(true);
                const simplyBooksGoogleAuthEndpoint = `${getMainServerEndpoint(Environment, apiStage)}/simplybooks/${session.userEmail}/google_authcode`;
                const simplyBooksGoogleAuthHeaders = {headers: {'Authorization': session.idToken, 'Content-Type': 'application/x-www-form-urlencoded'}};
                axios.post(simplyBooksGoogleAuthEndpoint, payload, simplyBooksGoogleAuthHeaders).then(response => {
                    console.debug(`pages.Connect.GoogleAuthSuccess.simplyBooksGoogleAuthEndpoint status code: ${response.status}`)
                    if (response.status === 200) {
                        window.alert(`Your Google account has been linked!`)
                        setStepNumber(2);
                        setGoogleAuthStatus(true);
                        setIsLoading(false);
                    } else {
                        console.debug(`pages.Connect.GoogleAuthSuccess.simplyBooksGoogleAuthEndpoint :A Server Error occurred while exchanging the Google Authorization Code`)
                        setIsLoading(false);
                    }
                }).catch(error => {
                    console.debug(`pages.Connect.GoogleAuthSuccess.getUserSession.simplyBooksGoogleAuthEndpoint Error`)
                    console.error(error)
                    setIsLoading(false);
                });
            }).catch(error => {
                console.debug(`pages.Connect.GoogleAuthSuccess.getUserSession Error`)
                console.error(error)
                setIsLoading(false);
            });
        } catch (warning) {
            console.debug('pages.Connect.GoogleAuthSuccess.warning:')
            console.debug(warning)
            setIsLoading(false);
        }
    };

    const GoogleAuthError = (error) => {
        console.debug(`pages.Connect.ConnectView.GoogleAuthError:`)
        console.log(error);
    };

    const GoogleAuthSignIn = () => {
        // scope: https://www.googleapis.com/auth/spreadsheets
        // redirect_uri: `${getBaseSiteUrl(Environment, apiStage)}`
        const Login = useGoogleLogin({
            onSuccess: response => GoogleAuthSuccess(response),
            onError: error => GoogleAuthError(error),
            flow: 'auth-code',
            scope: "https://www.googleapis.com/auth/drive.file",
            overrideScope: true,
            include_granted_scopes: true,
            select_account: true,
            redirect_uri: "https://simplybooks.io"
        });
        // <button type={"button"} onClick={() => Login()}>
        //     Connect Google Account
        // </button>
        return (
            <div className={'google-sign-in-div'}>
                <GoogleButton
                    onClick={() => {Login()}}
                    type={"light"}
                    disabled={googleAuthStatus}
                    className={'google-auth-button'}
                />
            </div>
        );
    };

    const onClickWizard = (event) => {
        event.preventDefault();

        if (!isEligible && !showWizard === true && googleAuthStatus) {
            window.alert('You are currently at your connection limit, likely due to connections for other company sheets you have.\n\nVisit your Account Settings to upgrade your connection limit.')

        } else {
            if (showWizard) {
                setShowWizard(!showWizard);
                setReRender(!reRender);
            } else {
                setShowWizard(!showWizard);
            }
        }
    };

    const ReconnectDisconnectedItems = () => {
        if (!requiresPlaidReconnect) {
            return <div></div>;

        } else {
            return (
                <div className={'reconnect-plaid-div'}>
                    <div>
                        <p className={'reconnect-p-1'}>
                            It looks like we are unable to access your bank for some accounts, reconnect by clicking below.
                        </p>
                        <PlaidLinkDiv
                            link_token={window.sessionStorage.getItem('link_token')}
                            button_text={'Re-Connect Account'}
                            reconnect={true}
                        />
                        <br />
                        <p className={'reconnect-p-2'}>
                            If you have other available bank connections, you will not be able to utilize them until you
                            either remove the disconnected account(s), or reconnect.
                        </p>
                    </div>
                </div>
            );
        }
    };

    const ConnectAdditionalAccounts = () => {
        if (!isEligible) {
            return (
                <div>
                    <div className={'connect-additional-accounts'}>
                        <p>Your are at your connection-limit. Please upgrade your account to allow additional bank connections.</p>
                    </div>
                    <ReconnectDisconnectedItems />
                </div>
            );
        } else {
            if (requiresPlaidReconnect) {
                return <ReconnectDisconnectedItems />;

            } else {
                return (
                    <div className={'connect-additional-accounts'}>
                        <p>Ready to connect additional bank accounts to this sheet? Click below to add more:</p>
                        <PlaidLinkDiv
                            link_token={window.sessionStorage.getItem('link_token')}
                            button_text={'Add Banks to this sheet'}
                            reconnect={false}
                        />
                    </div>
                );

            }
        }
    };

    const onClickDeleteCompany = (event) => {
        event.preventDefault();
        if (window.confirm(
            `Are you sure you want to delete this company?\n\n${companyScope}\n\nThis will sever SimplyBooks' connection to the bank(s) and Google Sheet for this company, but will not remove the sheet from your Google Account.`
        )) {
            setSheetLink("");
            getUserSession().then(session => {
                setIsLoading(true);
                const delCoEndpoint = `${getMainServerEndpoint(Environment, apiStage)}/simplybooks/${session.userEmail}/delete_company`;
                const delCoPayload = {"company": companyScope};
                const delCoConfig = {headers: {"Authorization": session.idToken, "Content-Type": "application/json"}};
                axios.post(delCoEndpoint, delCoPayload, delCoConfig).then(res => {

                    if (res.status === 200) {
                        window.alert(`Successfully deleted sheet:\n\n${companyScope}`)
                        window.sessionStorage.setItem('company_scope', "");
                        setCompanyScope("");
                        setIsLoading(false);

                    }

                    if (res.status === 500) throw new Error(`Error deleting sheet: ${companyScope}; [${res.status}] ${res.data['msg']}`);
                    window.sessionStorage.setItem('company_scope', "");
                    setCompanyScope("");
                    toggleSheetMenu(!showSheetMenu);
                    setIsLoading(false);

                }).catch(error => {
                    handleError(
                        'pages.connect.onClickDeleteCompany.getUserSession.delCoEndpoint',
                        error,
                        false,
                        '',
                        'Caught error in onClickDeleteCompany'
                    );
                    setIsLoading(false);
                });

            }).catch(error => {
                handleError(
                    'pages.connect.onClickDeleteCompany',
                    error,
                    false,
                    '',
                    'Caught error in onClickDeleteCompany'
                );
                setIsLoading(false);
            });

        }
    };

    const onClickOpenLink = (event, link = "") => {
        event.preventDefault();
        window.open(link, '_blank');
    };

    const AccountConnectionTable = () => {
        if (specificAccounts.length === 0) {
            return (
                <div>
                    <WizardQueue />
                    <TaskBar />
                </div>
            );

        } else {
            return (
                <div>
                    <ConnectionTable
                        accounts={accountData}
                        onClickRemove={onClickRemove}
                        companyScope={companyScope}
                    />
                    <ConnectAdditionalAccounts />
                    <TaskBar />
                </div>
            );
        }
    };

    const WizardQueue = () => {
        console.debug(`[WizardQueue] companyOptions: ${companyOptions}`)
        console.debug(`[WizardQueue] current_company: ${companyScope}`)
        console.debug(`[WizardQueue] hasConnections: ${hasConnections}`)
        console.debug(`[WizardQueue] specificAccounts (length): ${specificAccounts}`)

        // <UncontrolledTooltip target={'connect-your-bank'} placement={'right'}>
        //     Click here to connect a bank to this sheet.
        // </UncontrolledTooltip>

        if (googleAuthStatus && !isEmpty(companyScope)) {
            return (
                <div className={'wizard-button'}>
                    <button
                        onClick={e => onClickWizard(e)}
                        onMouseOver={e => e.currentTarget.style.boxShadow = '0 0 10px 10px #ffffff'}
                        onMouseOut={e => e.currentTarget.style.boxShadow = '10px 10px 8px #888888'}
                    >
                        <h3 id={'connect-your-bank'}>Connect your Bank</h3>
                    </button>
                    <div className={'wizard-sub-text'}>
                        <p>Your sheet hasn't been fully created yet.</p>
                        <p>Connect a bank account to this sheet to get started.</p>
                    </div>
                </div>
            );

        } else {

            // <UncontrolledTooltip target={'get-started'} placement={'right'}>
            //     Click here to get started creating your first sheet.
            // </UncontrolledTooltip>

            return (
                <div className={'wizard-button'}>
                    <button
                        onClick={e => onClickWizard(e)}
                        onMouseOver={e => e.currentTarget.style.boxShadow = '0 0 10px 10px #ffffff'}
                        onMouseOut={e => e.currentTarget.style.boxShadow = '10px 10px 8px #888888'}
                    >
                        <h3 id={'get-started'}>Get started here</h3>
                    </button>
                    <div className={'wizard-sub-text'}>
                        <p>Your sheets will appear here once you have at least one connection set up.</p>
                    </div>
                </div>
            );

        }
    };

    const GoogleReconnect = () => {
        if (googleAuthStatus) {
            return (<div></div>);

        } else if (specificAccounts.length === 0) {
            return (<div></div>);

        } else {
            return (
                <div className={'google-reconnect'}>
                    <div className={'grc-div-1'}>
                        <p>It appears that our access to your Google Sheets has been interrupted. Click below to reconnect</p>
                    </div>
                    <div className={'grc-div-2'}>
                        <GoogleAuthSignIn />
                    </div>
                </div>
            );
        }
    };

    const onCompanyChange = (event, co) => {
        event.preventDefault();
        if (co === "Create New") {
            setStepNumber(2);
            setCompanyScope("");
            window.sessionStorage.setItem('company_scope', "");
            setSheetLink("");
            setShowWizard(!showWizard);
            // toggleSheetMenu(!showSheetMenu);

        } else {
            console.debug(`onCompanyChange: ${co}`)
            window.sessionStorage.setItem('company_scope', `${co}`);
            setCompanyScope(co);
            objectDebug('companyScope', companyScope);
            let filteredAccounts = accountData.filter(a => a['company'] === companyScope);
            objectDebug('connect.onCompanyChange.filteredAccounts', filteredAccounts);
            if (filteredAccounts.length > 0) {
                setSpecificAccounts(filteredAccounts);
                toggleSheetMenu(!showSheetMenu);
                setReRender(!reRender);

            } else {
                setSpecificAccounts([]);
                toggleSheetMenu(!showSheetMenu);
                setReRender(!reRender);

            }

        }
    };

    const AvailableSheets = () => {
        if (!showSheetMenu) {
            return (<div></div>);

        } else {
            const options_ = companyOptions.map((opt, idx) => {
                return (
                    <div className={'sheet-option-tile'} key={opt}>
                        <Card
                            className={'sheet-option-card'}
                            id={opt}
                            onMouseOver={e => e.currentTarget.style.boxShadow = '0 0 .25rem .25rem #1fc6d0'}
                            onMouseOut={e => e.currentTarget.style.boxShadow = '0 0 0 0 #ffffff'}
                            onClick={e => onCompanyChange(e, e.currentTarget.id)}
                        >
                            <CardBody>
                                <CardTitle className={'sheet-option-card-header'}>
                                    {opt}
                                </CardTitle>
                                <CardBody className={'sheet-option-card-body'}>
                                    <img
                                        src={sheet}
                                        alt={'sheet-icon'}
                                        className={'sheet-icon'}
                                    />
                                </CardBody>
                            </CardBody>
                        </Card>
                    </div>
                );
            });
            return (
                <div className={'sheet-option-tile-menu'}>
                    <div className={'inner'}>
                        <h6>Select a sheet</h6>
                        <span>
                            {options_}
                        </span>
                    </div>
                </div>
            );
        }}

    const onClickBackToMenu = (event) => {
        event.preventDefault();
        setCompanyScope("");
        window.sessionStorage.setItem('company_scope', "");
        setSheetLink("");
        toggleSheetMenu(!showSheetMenu);
        if (showWizard) {
            setShowWizard(!showWizard);
        }
    };

    const onClickOpenOptions = (event) => {
        event.preventDefault();
        setOptionsOpen(!optionsOpen);
    };

    const DashboardOptions = () => {
        if (!showSheetMenu) {
            return (
                <div className={'options-toolbar'}>
                    <Dropdown isOpen={optionsOpen} toggle={onClickOpenOptions} direction={'down'}>
                        <DropdownToggle caret>Options</DropdownToggle>
                        <DropdownMenu >
                            <DropdownItem onClick={e => handleShowImport(e)}>Import data</DropdownItem>
                            <DropdownItem onClick={e => handleReportIssue(e)}>Report an issue</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            );

        } else {
            return (
                <div className={'options-toolbar'}>
                    <Dropdown isOpen={optionsOpen} toggle={onClickOpenOptions} direction={'down'}>
                        <DropdownToggle caret>Options</DropdownToggle>
                        <DropdownMenu >
                            <DropdownItem onClick={e => handleReportIssue(e)}>Report an issue</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            );
        }

    };

    const TaskBar = () => {
        let google_button = <button
            role={'link'}
            onClick={e => onClickOpenLink(e, sheetLink)}
            style={{backgroundColor: '#239c13', cursor: 'pointer'}}
            onMouseOver={e => e.currentTarget.style.backgroundColor = '#2bc716'}
            onMouseOut={e => e.currentTarget.style.backgroundColor = '#239c13'}
        >
            Open sheet in Google
        </button>;
        if (isEmpty(sheetLink)) {
            google_button = <button
                role={'link'}
                disabled={true}
                onClick={e => onClickOpenLink(e, sheetLink)}
                style={{backgroundColor: '#888888', cursor: 'default'}}
            >
                Open sheet in Google
            </button>;
        }
        return (
            <div className={'account-task-bar'}>
                <ButtonToolbar>
                    <ButtonGroup className={'button-group-google'}>
                        {google_button}
                    </ButtonGroup>
                    <ButtonGroup className={'button-group-delete'}>
                        <button
                            type={'submit'}
                            onMouseOver={e => (e.currentTarget.style.backgroundColor = '#f54e42')}
                            onMouseOut={e => (e.currentTarget.style.backgroundColor = 'red')}
                            onClick={e => onClickDeleteCompany(e)}
                        >
                            Delete sheet
                        </button>
                    </ButtonGroup>
                </ButtonToolbar>
            </div>
        );
    };

    const BackToMenuButton = () => {
        if (!showSheetMenu && !showImportData && !reportIssue) {
            return (
                <button
                    className={'menu-button'}
                    style={{backgroundColor: '#333', color: '#ffffff', fontWeight: 'bold'}}
                    onMouseOver={e => e.currentTarget.style.backgroundColor = '#575757'}
                    onMouseOut={e => e.currentTarget.style.backgroundColor = '#333'}
                    onClick={e => onClickBackToMenu(e)}
                >
                    {"< Back to menu"}
                </button>
            );

        } else if (!showSheetMenu && showImportData) {
            return (
                <button
                    className={'menu-button'}
                    style={{backgroundColor: '#333', color: '#ffffff', fontWeight: 'bold'}}
                    onMouseOver={e => e.currentTarget.style.backgroundColor = '#575757'}
                    onMouseOut={e => e.currentTarget.style.backgroundColor = '#333'}
                    onClick={e => handleShowImport(e)}
                >
                    {"< Back to sheet"}
                </button>
            );

        } else if (!showSheetMenu && reportIssue) {
            return (
                <button
                    className={'menu-button'}
                    style={{backgroundColor: '#333', color: '#ffffff', fontWeight: 'bold'}}
                    onMouseOver={e => e.currentTarget.style.backgroundColor = '#575757'}
                    onMouseOut={e => e.currentTarget.style.backgroundColor = '#333'}
                    onClick={e => handleReportIssue(e)}
                >
                    {"< Back to sheet"}
                </button>
            );

        } else if (showSheetMenu && reportIssue) {
            return (
                <button
                    className={'menu-button'}
                    style={{backgroundColor: '#333', color: '#ffffff', fontWeight: 'bold'}}
                    onMouseOver={e => e.currentTarget.style.backgroundColor = '#575757'}
                    onMouseOut={e => e.currentTarget.style.backgroundColor = '#333'}
                    onClick={e => handleReportIssue(e)}
                >
                    {"< Back to menu"}
                </button>
            );

        } else {
            return (
                <button
                    className={'menu-button'}
                    style={{backgroundColor: '#1fc6d0', color: '#ffffff', fontWeight: 'bold'}}
                    onMouseOver={e => e.currentTarget.style.backgroundColor = '#22e4f0'}
                    onMouseOut={e => e.currentTarget.style.backgroundColor = '#1fc6d0'}
                    onClick={e => onCompanyChange(e, 'Create New')}
                >
                    {"New Sheet"}
                </button>
            );
        }
    };

    const onClickIgnoreUpdate = (event) => {
        event.preventDefault();
        window.sessionStorage.setItem('suppress-training-notification', '1');
        setHasTrainingJob(false);
    };

    const LoadingTrainingJob = () => {
        const suppress = window.sessionStorage.getItem('suppress-training-notification');
        if (!hasTrainingJob || suppress === "1") {
            return <div></div>;

        } else {
            return (
                <div className={'training-in-progress'}>
                    <h5>Your algorithm is being updated</h5>
                    <h6>If you intend to create a new bank connection, please wait for this to finish to take full advantage of this update.</h6>
                    <h6>The page will refresh every 30 seconds until this is complete. Otherwise, feel free to continue by clicking 'Ignore'</h6>
                    <br />
                    <Spinner color={'info'} className={'spinner'} type={'grow'}/>
                    <br />
                    <br />
                    <button
                        className={'menu-button'}
                        style={{backgroundColor: '#1fc6d0', color: '#ffffff', fontWeight: 'bold'}}
                        onMouseOver={e => e.currentTarget.style.backgroundColor = '#22e4f0'}
                        onMouseOut={e => e.currentTarget.style.backgroundColor = '#1fc6d0'}
                        onClick={e => onClickIgnoreUpdate(e)}
                    >
                        Ignore
                    </button>

                </div>
            );
        }
    };

    const onClickImportInstead = (event) => {
        event.preventDefault();
        console.debug(`---> onClickImportInstead`)
        const new_company = window.sessionStorage.getItem('new_company');
        setCompanyScope(new_company);
        toggleSheetMenu(false);
        setShowImportData(true);

    };

    const ConnectView = () => {
        // window.sessionStorage.getItem('link_token')
        if (showSheetMenu && companyOptions.length === 0) {
            console.debug('[SHEET MENU]')
            return (
                <div className={"connection-view"}>
                    <div className={'connection-view-header'}>
                        <h2>Sheet Menu</h2>
                    </div>
                    <WizardQueue />
                    <ConnectionWizard
                        showWizard={showWizard}
                        onClickWizard={onClickWizard}
                        stepNumber={stepNumber}
                        setStepNumber={setStepNumber}
                        PlaidLinkDiv={<PlaidLinkDiv link_token={window.sessionStorage.getItem('link_token')} button_text={'Connect'} reconnect={false} />}
                        GoogleAuthSignIn={GoogleAuthSignIn}
                        googleAuthStatus={googleAuthStatus}
                        setGoogleAuthStatus={setGoogleAuthStatus}
                        allAccounts={accountData}
                        onClickImportInstead={onClickImportInstead}
                    />
                    <GoogleReconnect />
                </div>
            );

        } else if (showSheetMenu) {
            return (
                <div className={"connection-view"}>
                    <div className={'connection-view-header'}>
                        <BackToMenuButton />
                        <DashboardOptions />
                        <h2>Sheet Menu</h2>
                    </div>
                    <LoadingTrainingJob />
                    <AvailableSheets />
                    <ConnectionWizard
                        showWizard={showWizard}
                        onClickWizard={onClickWizard}
                        stepNumber={stepNumber}
                        setStepNumber={setStepNumber}
                        PlaidLinkDiv={<PlaidLinkDiv link_token={window.sessionStorage.getItem('link_token')} button_text={'Connect'} reconnect={false} />}
                        GoogleAuthSignIn={GoogleAuthSignIn}
                        googleAuthStatus={googleAuthStatus}
                        setGoogleAuthStatus={setGoogleAuthStatus}
                        allAccounts={accountData}
                        onClickImportInstead={onClickImportInstead}
                    />
                    <GoogleReconnect />
                </div>
            );

        } else {
            if (!isEmpty(companyScope)) {
                return (
                    <div className={"connection-view"}>
                        <div className={'connection-view-header'}>
                            <BackToMenuButton />
                            <DashboardOptions />
                            <h2>Bank Connection Dashboard</h2>
                            <h5>Viewing sheet: {companyScope}</h5>
                        </div>
                        <AccountConnectionTable />
                        <ConnectionWizard
                            showWizard={showWizard}
                            onClickWizard={onClickWizard}
                            stepNumber={stepNumber}
                            setStepNumber={setStepNumber}
                            PlaidLinkDiv={<PlaidLinkDiv link_token={window.sessionStorage.getItem('link_token')} button_text={'Connect'} reconnect={false} />}
                            GoogleAuthSignIn={GoogleAuthSignIn}
                            googleAuthStatus={googleAuthStatus}
                            setGoogleAuthStatus={setGoogleAuthStatus}
                            allAccounts={accountData}
                            onClickImportInstead={onClickImportInstead}
                        />
                        <GoogleReconnect />
                    </div>
                );
            }
        }
    };

    const handleShowImport = (e) => {
        e.preventDefault();
        console.log('---> handleShowImport')
        setShowImportData(!showImportData);
        if (showWizard) {
            setShowWizard(!showWizard);
        }
    };

    const onClickUploadData = (event, payload = {}) => {
        event.preventDefault();
        objectDebug('onClickUploadData', payload);
        const filename = document.getElementById('data-import-file').value;
        console.debug(filename)

        const reader = new FileReader();
        reader.onload = () => {
            try {
                const result = reader.result;
                objectDebug('Reader Result', result);
                getUserSession().then(session => {
                    setIsLoading(true);
                    const uploadEndpoint = `${getMainServerEndpoint(Environment, apiStage)}/simplybooks/${session.userEmail}/import_data`;
                    const uploadPayload = new FormData();
                    uploadPayload.append('data', result);
                    uploadPayload.append('company', companyScope);
                    const uploadHeaders = {headers: {'Authorization': session.idToken, 'Content-Type': 'multipart/form-data'}};
                    try {
                        axios.post(uploadEndpoint, uploadPayload, uploadHeaders).then(response => {
                            if (response.status === 200) {
                                window.alert('Data Import Successful!\n\nWe are retraining your account algorithm so that future transactions will recognize the provided data as well as use the categories provided in its predictions.\n\nThis could take about 5-10 minutes to complete, so hold off on any new bank connections while we do our work.');
                                setIsLoading(false);

                            } else if (response.status === 400) {
                                window.alert(`We were unable to validate the format of your data.\n\n`);
                                setIsLoading(false);

                            }
                        });
                    } catch (error) {
                        handleError(
                            `[${source}][${element}] > onClickUploadData.getUserSession.POST`,
                            error,
                            true,
                            `An error occurred while uploading your data.\n\nPlease refresh the page and try again.`,
                            'Caught error in onClickUploadData.uploadEndpoint'
                        );
                        setIsLoading(false);
                    }

                }).catch(error => {
                    handleError(
                        `[${source}][${element}] > onClickUploadData.getUserSession`,
                        error,
                        true,
                        `An error occurred while uploading your data.\n\nPlease refresh the page and try again.`,
                        'Caught error in onClickUploadData'
                    );
                    setIsLoading(false);
                });
            } catch (error) {
                handleError(
                    `[${source}][${element}] > onClickUploadData`,
                    error,
                    true,
                    `An error occurred while uploading your data.\n\nPlease refresh the page and try again.`,
                    'Caught overArching Exception in onClickUploadData'
                );
                setIsLoading(false);
            }
        };
        try {
            reader.readAsText(document.getElementById('data-import-file').files[0]);
        } catch (error) {
            console.warn(error?.message)
        }

    };

    const ImportCsvData = () => {
        return (
            <div className={"connection-view"}>
                <div className={'connection-view-header'}>
                    <BackToMenuButton />
                    <br />
                    <br />
                    <h2>Import Data</h2>
                    <h5>Importing for sheet: {companyScope}</h5>
                </div>
                <ImportDataInstructions />
                <div className={'upload-data-div'}>
                    <FormGroup>
                        <Input type={'file'} id={'data-import-file'} placeholder={'formatted csv file'} />
                    </FormGroup>
                    <div className={'upload-button-div'}>
                        <button
                            className={'upload-button'}
                            style={{backgroundColor: '#1fc6d0', color: '#ffffff', fontWeight: 'bold'}}
                            onMouseOver={e => e.currentTarget.style.backgroundColor = '#22e4f0'}
                            onMouseOut={e => e.currentTarget.style.backgroundColor = '#1fc6d0'}
                            onClick={e => onClickUploadData(e)}
                        >
                            {"Upload"}
                        </button>
                    </div>
                </div>

            </div>
        );
    };

    const handleReportIssue = (e) => {
        e.preventDefault();
        console.debug('---> handleReportIssue')
        setReportIssue(!reportIssue);
    };

    const onClickReportIssue = (event) => {
        event.preventDefault();
        console.debug('---> onClickReportIssue')
        try {
            const issueCategory = document.querySelector('#issue-category').value;
            if (issueCategory === "Make a selection") {
                window.alert("You must select a general issue category to report a problem.\n\nIf your specific issue is different from the options provided, choose 'other'.");

            }
            let issueSubCategory = null;
            if (!isEmpty(document.querySelector('#issue-category-custom'))) {
                issueSubCategory = document.querySelector('#issue-category-custom').value;
                if (isEmpty(issueSubCategory)) {
                    window.alert("You must provide a general topic that your issue relates to when using a custom issue category.\n\nPlease try again.");

                }
            }
            const issueBody = document.querySelector('#issue-body').value;
            if (isEmpty(issueBody)) {
                window.alert("Please provide a short statement describing your issue and try again.");

            }
            getUserSession().then(session => {
                setIsLoading(true);
                const reportUri = `${getMainServerEndpoint(Environment, apiStage)}/simplybooks/${session.userEmail}/report_issue`;
                const reportConfig = {headers: {"Authorization": session.idToken, "Content-Type": "application/json"}};
                const reportPayload = {
                    "category": issueCategory,
                    "sub_category": issueSubCategory,
                    "body": issueBody
                }
                axios.post(reportUri, reportPayload, reportConfig).then(res => {
                    if (res.status === 200) {
                        window.alert('Your issue was successfully sent to the SimplyBooks team!\n\nYou can expect a response within 24 hours or sooner.');

                    }
                    setIsLoading(false);

                }).catch(error => {
                    handleError(
                        `[${source}][${element}] > onClickReportIssue.getUserSession.post`,
                        error,
                        true,
                        `An error occurred while reporting your issue.\n\nPlease refresh the page and try again.`,
                        'Caught Exception in onClickReportIssue.getUserSession.axios.post()'
                    );
                    setIsLoading(false);

                });

            }).catch(error => {
                handleError(
                    `[${source}][${element}] > onClickReportIssue.getUserSession`,
                    error,
                    true,
                    `An error occurred while reporting your issue.\n\nPlease refresh the page and try again.`,
                    'Caught Exception in onClickReportIssue.getUserSession()'
                );
                setIsLoading(false);

            });

        } catch (error) {
            handleError(
                `[${source}][${element}] > onClickReportIssue`,
                error,
                true,
                `An error occurred while reporting your issue.\n\nPlease refresh the page and try again.`,
                'Caught overArching Exception in onClickReportIssue'
            );
            setIsLoading(false);
        }
    };

    const ReportAnIssue = () => {
        const [showCustomCategory, setShowCustomCategory] = useState(false);

        const toggleCategoryDropDown = (event) => {
            event.preventDefault();
            console.debug('---> toggleCategoryDropDown')
            try {
                const dropDownValue = document.querySelector('#issue-category').value
                if (dropDownValue === "Other" && !showCustomCategory) {
                    setShowCustomCategory(!showCustomCategory);

                } else if (showCustomCategory && dropDownValue !== "Other") {
                    setShowCustomCategory(!showCustomCategory);

                }
            } catch (error) {
                console.warn(error?.message || error);
            }
        };

        return (
            <div className={"connection-view"}>
                <div className={'connection-view-header'}>
                    <BackToMenuButton />
                    <br />
                    <br />
                    <h2>Report an issue</h2>
                </div>
                <div className={'upload-data-instructions'}>
                    <div className={'explanation'}>
                        <p>
                            Use the form below to describe the issue you are experiencing.
                        </p>
                        <p>
                            Whether you have a technical issue, or just need help with something, a SimplyBooks.io representative will respond within 24 hours.
                        </p>
                    </div>
                </div>
                <div className={'upload-data-div'}>
                    <FormGroup>
                        <Input onChange={e => toggleCategoryDropDown(e)} type={'select'} defaultValue={'Make a selection'} id={'issue-category'}>
                            <option key={0} value={'Make a selection'} hidden>Make a selection</option>
                            <option key={1} value={'Bank Connection'}>Bank Connection</option>
                            <option key={2} value={'Transaction Sync'}>Transaction Sync</option>
                            <option key={3} value={'Category prediction'}>Category prediction</option>
                            <option key={4} value={'Sheet creation'}>Sheet creation</option>
                            <option key={5} value={'Google connection'}>Google connection</option>
                            <option key={6} value={'Billing'}>Billing</option>
                            <option key={7} value={'Accessibility'}>Accessibility</option>
                            <option key={8} value={'Other'}>Other</option>
                        </Input>
                        <br />
                        {!showCustomCategory ? <div></div> : <Input type={'text'} id={'issue-category-custom'} placeholder={'Custom issue category'} />}
                        {!showCustomCategory ? <div></div> : <br />}
                        <Input type={'textarea'} id={'issue-body'} placeholder={'describe the issue you are experiencing'} />
                    </FormGroup>
                    <div className={'upload-button-div'}>
                        <button
                            className={'upload-button'}
                            style={{backgroundColor: '#1fc6d0', color: '#ffffff', fontWeight: 'bold'}}
                            onMouseOver={e => e.currentTarget.style.backgroundColor = '#22e4f0'}
                            onMouseOut={e => e.currentTarget.style.backgroundColor = '#1fc6d0'}
                            onClick={e => onClickReportIssue(e)}
                        >
                            {"Send"}
                        </button>
                    </div>
                </div>

            </div>
        );
    };

    const View = () => {
        if (isLoading && !fetchingNewAccounts) {
            return <LoadingGIF message={'Loading...'} />;

        } else if (isLoading && fetchingNewAccounts) {
            return <LoadingGIF message={loadingMessage} />;

        } else if (showImportData) {
            return <ImportCsvData />;

        } else if (reportIssue) {
            return <ReportAnIssue />;

        } else {
            return <ConnectView />;
        }
    };


    return (
        <div className={'sign-up-splash'}>
            <Container fluid={true} style={{cursor: 'default'}}>
                <Row xs={1} className={'row-1'}>
                    <Col className={'sign-up-col'}>
                        <div className={'splash-inner'}>
                            <div className={'new-membership-body'}>
                                <View />
                            </div>
                            <Footer />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );


};

export default withAuthenticator(Connect);