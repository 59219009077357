/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Badge,
    ListGroup,
    ListGroupItemHeading, ListGroupItemText
} from 'reactstrap'
import './page.scss';
import infographic_gif from '../assets/ezgif.com-animated-gif-maker.gif';
import accounting from '../assets/Screenshot-2024-07-03-11114.png'
import connect from '../assets/i_connect.png';
import intel from '../assets/i_intelligence.png';
import share from '../assets/i_share.png';
import graph from '../assets/i_analysis.png';
import profit from '../assets/i_profit.png';
import upload from '../assets/cloud-computing_164109.png';
import learn from '../assets/calendar_833593.png';
import Footer from "../components/footer/footer";
import { getPageLocation } from "../utils/getPageLocation";
import { apiStage, Environment } from "../utils/constants";


const Home = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const onClickNavigateToSignUp = (event) => {
        event.preventDefault();
        window.location = `${getPageLocation(Environment, apiStage, 'sign-up')}`;
    };

    return (
        <div className={'top-splash'}>
            <Container className={'top-container'} fluid={true}>
                <Row xs={1} className={'row-1'}>
                    <Col>
                        <div className={'splash-inner'}>
                            <header>
                                <h3>The easiest bank-to-spreadsheet tool on the market.</h3>
                                <br />
                                <h5>See the how-to video <a href={'https://www.youtube.com/watch?v=TE2RbPjf7u4'}>HERE</a></h5>
                            </header>
                            <div className={'page-body'}>
                                <div className={'mission'} style={{}}>
                                    <div>
                                        <h5>
                                            Our mission at SimplyBooks is to take your annual bookkeeping from the being
                                            a complex and laborious task,
                                            to one that is fully automated and easy to manage from anywhere that you can
                                            access your Google Sheets, all at
                                            a lower price than you can find anywhere else with more powerful intelligent
                                            automation.
                                        </h5>
                                    </div>
                                </div>
                                <div className={'full-width-0'}>
                                    <img className={'home-gif'} src={infographic_gif} alt={'infographic'}/>
                                </div>
                                <span className={'text-and-icon'}>
                                    <div className={'icon-image-sm'}>
                                        <img src={connect} alt={'connect'}/>
                                    </div>
                                    <div className={'text right'}>
                                        <h5>
                                            SimplyBooks works by connecting your online banking transaction feed to your Google Sheets,
                                            and automatically adds new transactions as additional rows when new data comes from your bank.
                                        </h5>
                                    </div>
                                </span>
                                <span className={'text-and-icon'}>
                                    <div className={'text left'}>
                                        <h5>
                                            Using a sophisticated learning algorithm that is personalized to you and your transaction data,
                                            SimplyBooks automates the categorization process as it learns what you do.
                                        </h5>
                                    </div>
                                    <div className={'icon-image'}>
                                        <img src={intel} alt={'intel'}/>
                                    </div>
                                </span>
                                <span className={'text-and-icon'}>
                                    <div className={'icon-image'}>
                                        <img src={share} alt={'share'}/>
                                    </div>
                                    <div className={'text right'}>
                                        <h5>
                                            Share your SimplyBooks sheets with your accountant to streamline your end-of-year tax filings.
                                        </h5>
                                    </div>
                                </span>
                                <span className={'text-and-icon'}>
                                    <div className={'text left'}>
                                        <h5>
                                            Easily utilize your transaction data through your own formulas to visualize trends, create budgeting plans, and generate reports like profit & loss.
                                        </h5>
                                    </div>
                                    <div className={'icon-image'}>
                                        <img src={graph} alt={'analyze'}/>
                                    </div>
                                </span>
                                <span className={'text-and-icon'}>
                                    <div className={'icon-image'}>
                                        <img src={profit} alt={'profit'}/>
                                    </div>
                                    <div className={'text right'}>
                                        <h5>
                                            Spend more time growing your business and less time worrying about accounting!
                                        </h5>
                                    </div>
                                </span>
                                <div className={'full-width-1'}>
                                    <h4>
                                        Utilize the SimplyBooks algorithm in two primary ways
                                    </h4>
                                    <span className={'double'}>
                                        <div className={'left'}>
                                            <h5>Learn as you go</h5>
                                            <img src={learn} alt={'learn-over-time'}/>
                                            <p>
                                                This is the default behavior of SimplyBooks, where your algorithm is trained over time depending on your usage.
                                            </p>

                                        </div>
                                        <div className={'right'}>
                                            <h5>Import data</h5>
                                            <img src={upload} alt={'import-data'}/>
                                            <p>Use this option to immediately enhance your account algorithms ability to automatically recognize transactions and others like it with the category you've used in the past.</p>
                                        </div>
                                    </span>
                                </div>
                                <span className={'section'}>
                                    <div className={'section-title'}>
                                        <h2>Features</h2>
                                        <hr/>
                                    </div>
                                </span>
                                <div className={'list'}>
                                    <ListGroup className={'feature-list'}>
                                        <ListGroupItemHeading className={'feature-heading'}>Category comprehension &
                                            inference</ListGroupItemHeading>
                                        <ListGroupItemText className={'feature-text'}>
                                            No more rule-based transaction matching. SimplyBooks uses in-house neural
                                            networks to continuously learn and grow with your transaction ledger on a
                                            personal level. No global models or mass inference.
                                        </ListGroupItemText>
                                        <hr/>
                                        <br/>
                                        <ListGroupItemHeading className={'feature-heading'}>Flexible chart of
                                            accounts</ListGroupItemHeading>
                                        <ListGroupItemText className={'feature-text'}>
                                            Categories that SimplyBooks uses when automatically categorizing are based
                                            on what you've categorized in the past. Whether it learned directly from
                                            your sheet, or through importing your ledger from a previous platform,
                                            SimplyBooks isn't bound by a rigidly defined chart of accounts.
                                        </ListGroupItemText>
                                        <hr/>
                                        <br/>
                                        <ListGroupItemHeading className={'feature-heading'}>Automatic Google Sheet
                                            creation & Management</ListGroupItemHeading>
                                        <ListGroupItemText className={'feature-text'}>
                                            SimplyBooks sheets automatically start a new sheet at the beginning of a new
                                            year, and come pre-loaded with category totaling formulas.
                                        </ListGroupItemText>
                                        <hr/>
                                        <br/>
                                        <ListGroupItemHeading className={'feature-heading'}>Multiple bank connections to
                                            one sheet</ListGroupItemHeading>
                                        <ListGroupItemText className={'feature-text'}>
                                            Bank at more than one place and need all of them together in one set of
                                            books? No problem.
                                        </ListGroupItemText>
                                        <hr/>
                                        <br/>
                                        <ListGroupItemHeading className={'feature-heading'}>Each account is separated
                                            into a new page within your sheet</ListGroupItemHeading>
                                        <ListGroupItemText className={'feature-text'}>
                                            Checking? Savings? Credit? All of your accounts land in one sheet on
                                            separate pages.
                                        </ListGroupItemText>
                                        <hr/>
                                        <br/>
                                        <ListGroupItemHeading className={'feature-heading'}>Keep several separate sheets
                                            with one subscription</ListGroupItemHeading>
                                        <ListGroupItemText className={'feature-text'}>
                                            Great for accountants! Securely sync bank transactions for multiple clients
                                            with one subscription to their own separate Google Sheets.
                                        </ListGroupItemText>
                                        <hr/>
                                        <br/>
                                        <ListGroupItemHeading className={'feature-heading'}>Easy
                                            migrations</ListGroupItemHeading>
                                        <ListGroupItemText className={'feature-text'}>
                                            Import your previous transaction ledger in a simple CSV format to fast
                                            forward the learning process for your account's algorithm, automating the
                                            rest of this year and years to come!
                                        </ListGroupItemText>
                                        <hr/>
                                        <br/>
                                        <ListGroupItemHeading className={'feature-heading'}>Peace of
                                            mind</ListGroupItemHeading>
                                        <ListGroupItemText className={'feature-text'}>
                                            When your books are based on what comes directly from your bank, it's hard
                                            to make mistakes that are common with other more complicated platforms.
                                        </ListGroupItemText>
                                        <hr/>
                                        <br/>
                                        <ListGroupItemHeading className={'feature-heading'}>Dropped the ball and need to
                                            catch up?</ListGroupItemHeading>
                                        <ListGroupItemText className={'feature-text'}>
                                            The catch-up game has never been quicker and easier. Sign up, connect, and
                                            sync! It's that simple to pull up to 2 years of transaction data into your
                                            Google Sheets.
                                        </ListGroupItemText>
                                        <hr/>
                                        <br/>
                                    </ListGroup>
                                </div>
                                <div className={'full-width-1'}>
                                    <h4>
                                        <a href={'/#/sign-up'}>Sign up</a> and get started today!
                                    </h4>
                                </div>
                            </div>
                            <Footer/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};


export default Home;