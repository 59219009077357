/*Copyright 2024, Blake Donahoo, LLC, All rights reserved.*/
import React from 'react';
import './footer.scss';
import { getPageLocation } from "../../utils/getPageLocation";
import { apiStage, Environment } from "../../utils/constants";

const Footer = () => {
    // <h5><a href={getPageLocation(Environment, apiStage, 'how-to')}>How to</a></h5>
    return (
        <div className={'footer-main-div'}>
            <header className={'footer-header'}>
                <h5><span>&copy; </span>2024 SimplyBooks.io</h5>
            </header>
            <div className={'footer-div'}>
                <span className={'footer-span'}>
                    <h5><a
                        onMouseOut={e => (e.currentTarget.style.color = '#555555')}
                        onMouseOver={e => (e.currentTarget.style.color = '#1fc6d0')}
                        href={getPageLocation(Environment, apiStage, 'about')}>About us
                    </a></h5>
                    <h5><a
                        onMouseOut={e => (e.currentTarget.style.color = '#555555')}
                        onMouseOver={e => (e.currentTarget.style.color = '#1fc6d0')}
                        href={getPageLocation(Environment, apiStage, 'faq')}>Faq
                    </a></h5>
                    <h5><a
                        onMouseOut={e => (e.currentTarget.style.color = '#555555')}
                        onMouseOver={e => (e.currentTarget.style.color = '#1fc6d0')}
                        href={getPageLocation(Environment, apiStage, 'partner-with-us')}>Partner with us
                    </a></h5>
                    <h5><a
                        onMouseOut={e => (e.currentTarget.style.color = '#555555')}
                        onMouseOver={e => (e.currentTarget.style.color = '#1fc6d0')}
                        href={getPageLocation(Environment, apiStage, 'privacy')}>Privacy policy
                    </a></h5>
                    <h5><a
                        onMouseOut={e => (e.currentTarget.style.color = '#555555')}
                        onMouseOver={e => (e.currentTarget.style.color = '#1fc6d0')}
                        href={getPageLocation(Environment, apiStage, 'terms-of-use')}>Terms of use
                    </a></h5>
                    <h5><a
                        onMouseOut={e => (e.currentTarget.style.color = '#555555')}
                        onMouseOver={e => (e.currentTarget.style.color = '#1fc6d0')}
                        href={getPageLocation(Environment, apiStage, 'google-limited-use-disclosure')}>Google Limited Use Disclosure
                    </a></h5>
                </span>
            </div>
        </div>
    );
};

export default Footer;