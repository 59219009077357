/*Copyright 2024, Blake Donahoo, LLC, All rights reserved.*/
import React, { useState } from "react";
import './settings.scss';

const ConnectionLimit = ({ connectionLimit, startingLimit, membershipLabel, onClickUpdateSubscription, stripeCustId, stripeSubId }) => {
    const [currLimit, setCurrLimit] = useState(`${connectionLimit}`);

    const onClickHandler = (event) => {
        event.preventDefault();
        const integerLimit = parseInt(currLimit);
        const action = event.currentTarget.value;

        if (integerLimit === 1 && action === "subtract") {
            console.debug(`Cannot have lower than ${integerLimit} for Bank Connection Limit.`)

        } else if (action === "subtract") {
            setCurrLimit(`${integerLimit - 1}`);

        } else if (action === "add") {
            setCurrLimit(`${integerLimit + 1}`);

        }
    };

    let buttonHoverColor = startingLimit === currLimit ? '#e34646' : '#25dad4';
    if (startingLimit === currLimit || membershipLabel === "Cancelled") {
        buttonHoverColor = '#e34646'
    }

    return (
        <div className={'connection-limit-div'}>
            <span>
                <strong>Bank Connection Limit:</strong>
            </span>
            <span className={'less'}>
                <label>
                    <button value={'subtract'} onClick={e => onClickHandler(e)}>
                        <strong>-</strong>
                    </button>
                </label>
            </span>
            <span style={{}}>
                {currLimit}
            </span>
            <span className={'more'}>
                <label>
                    <button value={'add'} onClick={e => onClickHandler(e)}>
                        <strong>+</strong>
                    </button>
                </label>
            </span>
            <span className={'sub-text'}>
                {
                    membershipLabel === "Cancelled" ?
                        <p style={{color: 'red'}}><i>*Unable to add connections to cancelled membership.</i></p> :
                        <div>
                            <p><i>* Billed at an additional $1.00/month or $12.00/year depending on membership selection.</i></p>
                            <p><i>* Changes to your next invoice amount are prorated by your billing cycle.</i></p>
                        </div>
                }
            </span>
            <div className={'save-changes'}>
                <button
                    disabled={startingLimit === currLimit || membershipLabel === "Cancelled"}
                    onMouseOver={e => (e.currentTarget.style.backgroundColor = buttonHoverColor)}
                    onMouseOut={e => (e.currentTarget.style.backgroundColor = '#1fc6d0')}
                    onClick={e => onClickUpdateSubscription(e, {
                        'type': 'connection_limit',
                        'value': currLimit,
                        'customer': stripeCustId,
                        'subscription': stripeSubId
                    })}
                >
                    Update Connection Limit
                </button>
            </div>
        </div>
    );

};

export default ConnectionLimit;