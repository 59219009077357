/*Copyright 2024, Blake Donahoo, LLC, All rights reserved.*/
import React, { useState } from "react";
import './settings.scss';

const MembershipPlanSelection = ({ membershipLabel, startingMembership, onClickUpdateSubscription, translateLabelToMembership, stripeCustId, stripeSubId }) => {
    const [currMembership, setCurrMembership] = useState(`${membershipLabel}`);

    let buttonHoverColor = '#25dad4';
    if (startingMembership === currMembership) {
        buttonHoverColor = '#e34646'
    }
    return (
        <div className={'membership-plan-selection'}>
            <span>
                <strong>Current Membership Plan:</strong>
            </span>
            <span className={'membership-opt-span'}>
                <label>
                    <input
                        id={'monthly-radio'}
                        name={'monthly-radio'}
                        type={'radio'}
                        value={'Basic Monthly'}
                        checked={currMembership === "Basic Monthly"}
                        onChange={e => setCurrMembership(e.currentTarget.value)}
                    />
                    Basic Monthly
                </label>
            </span>
            <span className={'membership-opt-span'}>
                <label>
                    <input
                        id={'annual-radio'}
                        name={'annual-radio'}
                        type={'radio'}
                        value={'Basic Annual'}
                        checked={currMembership === "Basic Annual"}
                        onChange={e => setCurrMembership(e.currentTarget.value)}
                    />
                    Basic Annual
                </label>
            </span>
            <span className={'sub-text'}>
                <p><i>*Billed at $9.99/month or $99.00/year</i></p>
            </span>
            <div className={'save-changes'}>
                <button
                    disabled={startingMembership === currMembership || currMembership === "Cancelled"}
                    onMouseOver={e => (e.currentTarget.style.backgroundColor = buttonHoverColor)}
                    onMouseOut={e => (e.currentTarget.style.backgroundColor = '#1fc6d0')}
                    onClick={e => onClickUpdateSubscription(e, {
                        'type': 'plan',
                        'value': translateLabelToMembership(currMembership),
                        'customer': stripeCustId,
                        'subscription': stripeSubId
                    })}
                >
                    Update Membership Plan
                </button>
            </div>
        </div>
    );

};

export default MembershipPlanSelection;