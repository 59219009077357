/*Copyright 2024, Blake Donahoo, LLC, All rights reserved.*/
import React from "react";
import './settings.scss';

const AutoCategorization = ({ onClickUpdateSubscription, autoCat, stripeCustId, stripeSubId }) => {

    const Button = () => {
        return (
            <div className={'save-changes'}>
                <button
                    onMouseOver={e => (e.currentTarget.style.backgroundColor = '#25dad4')}
                    onMouseOut={e => (e.currentTarget.style.backgroundColor = '#1fc6d0')}
                    onClick={e => onClickUpdateSubscription(e, {
                        'type': 'auto_cat',
                        'value': !autoCat,
                        'customer': stripeCustId,
                        'subscription': stripeSubId
                    })}
                >
                    {autoCat ? 'Disable Auto-Categorization' : 'Enable Auto-Categorization'}
                </button>
            </div>
        );
    };

    const CurrentStatus = () => {
        if (autoCat) {
            return (
                <div className={'status'}>
                    <strong>Current status: </strong><span style={{color: "green"}}>Enabled</span>
                </div>
            );
        } else {
            return (
                <div className={'status'}>
                    <strong>Current status: </strong><span style={{color: "red"}}>Disabled</span>
                </div>
            );
        }
    };

    return (
        <div className={'auto-categorization'}>
            <span>
                <strong>Enable / Disable Learning Algorithm:</strong>
                <p>
                    For a variety of reasons, you may want to disable SimplyBooks' ability to learn from your transactions and auto-fill your
                    category on new transactions.
                </p>
                <p>
                    This feature is enabled by default for all paid subscribers, but can be changed by using the button below.
                </p>
                <p style={{color: 'red'}}><i>
                    * Turning this off will result in SimplyBooks.io only syncing your transactions to your sheet(s) for any available accounts as they appear, without inferring a category.
                </i></p>
            </span>
            <CurrentStatus />
            <Button />
        </div>
    );

};

export default AutoCategorization;