/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import { fetchAuthSession, getCurrentUser, signOut } from 'aws-amplify/auth';


export class Session {
    constructor(userId, userName, signInDetails, userEmail, accessToken, idToken, credentials, identityId) {
        this.userId = userId;
        this.userName = userName;
        this.signInDetails = signInDetails;
        this.userEmail = userEmail;
        this.accessToken = accessToken;
        this.idToken = idToken;
        this.credentials = credentials;
        this.identityId = identityId;
    };
}

export async function isLoggedIn() {
    console.debug(`utils.session.isLoggedIn > Attempting ...`)
    try {
        await getCurrentUser();
        return true;
    } catch (isLoggedInError) {
        console.debug(`utils.session.isLoggedIn: False`)
        return false;
    }
}

export async function handleSignOut() {
    try {
        await signOut({global: true}).then(so => {
            console.log(`utils.session.handleSignOut.signOut: True`)
        });
    } catch (error) {
        console.debug('utils.session.handleSignOut: error signing out: ')
        console.error(error)
    }
}

export async function getUserSession() {
    try {
        console.debug(`utils.session.getUserSession > Attempting...`)
        const currentUser = await getCurrentUser();
        const userId = currentUser.userId;
        const userName = currentUser.username;
        const signInDetails = currentUser.signInDetails;
        let userEmail = signInDetails.loginId;
        const authSession = (await fetchAuthSession());
        const tokens = authSession.tokens ?? {};
        const credentials = authSession.credentials ?? {};
        const identityId = authSession.identityId ?? {};
        const accessToken = tokens.accessToken;
        const idToken = tokens.idToken;
        // console.debug(`utils.session.getUserSession > Retrieved`);
        // console.debug(`userId: ${userId}`)
        // console.debug(`userName: ${userName}`)
        // console.debug(`userEmail: ${userEmail}`)
        // console.debug(credentials)
        // console.debug(identityId)
        return new Session(userId, userName, signInDetails, userEmail, accessToken, idToken, credentials, identityId);
    } catch (error) {
        console.error(`utils.session.getUserSession Error`)
        console.error(error)
        // return new Session(null, null, null, null, null, null, null, null);
    }
}

export async function refreshSession() {
    try {
        console.debug(`utils.session.refreshSession > Attempting...`)
        const authSession = (await fetchAuthSession({forceRefresh: true}));
        const tokens = authSession.tokens ?? {};
        const credentials = authSession.credentials ?? {};
        const identityId = authSession.identityId ?? {};
        const accessToken = tokens.accessToken;
        const idToken = tokens.idToken;
        const currentUser = await getCurrentUser();
        const userId = currentUser.userId;
        const userName = currentUser.username;
        const signInDetails = currentUser.signInDetails;
        const userEmail = signInDetails.loginId;
        console.debug(`utils.session.refreshSession > Refreshed; CurrentUser: ${currentUser}; Tokens: ${tokens}`);
        return new Session(userId, userName, signInDetails, userEmail, accessToken, idToken, credentials, identityId);
    } catch (err) {
        console.error(`utils.session.refreshSession Error`)
        console.error(err)
        return new Session(null, null, null, null, null, null, null, null);
    }
}
