/*Copyright 2024, Blake Donahoo, All rights reserved.*/

export function handleError(location, error, alertBool, alertMessage, consoleMessage, object) {
    if (!alertBool && !object) {
        console.debug(`[handleError] Location: ${location}; Message: ${consoleMessage}`)
        console.error(error)

    } else if (!alertBool && object) {
        console.debug(`[handleError] Location: ${location}; Message: ${consoleMessage}`)
        console.debug(object)
        console.error(error)

    } else if (alertBool && !object) {
        console.debug(`[handleError] Location: ${location}; Message: ${consoleMessage}`)
        console.error(error)
        window.alert(alertMessage)

    } else if (alertBool && object) {
        console.debug(`[handleError] Location: ${location}; Message: ${consoleMessage}`)
        console.debug(object)
        console.error(error)
        window.alert(alertMessage)

    } else {
        console.debug(`[handleError] Location: ${location}; Message: ${consoleMessage}`)
        console.error(error)
    }
}

export function objectDebug(label, object) {
    console.debug(`[objectDebug] ${label} >>>`)
    console.log(object)
}

export function getAndDebug(value, label) {
    console.debug(`[getAndDebug] ${label} >>> ${value}`)
    return value;
}