/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React from "react";
import { signIn } from 'aws-amplify/auth';
import './sign-in-modal.scss';
import logo from '../../assets/Logo3_raw.png';
import { Modal, ModalBody, ModalHeader, FormGroup, Form, Label, Input, ModalFooter } from "reactstrap";


const SignInModal = ({ showSignIn, handleSignInModal, toggleSignInAndNavigate }) => {

    async function amplifySignIn (username, password) {
        try {
            const { isSignedIn, nextStep } = await signIn({username, password, options: {authFlowType: "USER_PASSWORD_AUTH"}});
            // const { isSignedIn, nextStep } = await signIn({username, password});
            console.log(`SignInModal.signIn.isSignedIn = ${isSignedIn}`)
            console.log(`SignInModal.signIn.nextStep = ${nextStep}`)
            return { isSignedIn, nextStep };
        } catch (error) {
            console.debug(`SignInModal.signIn Error`)
            console.error(error)
            console.debug(error.message ?? error)
            return {isSignedIn: false, nextStep: null};
        }
    }

    const handleSignIn = (e) => {
        e.preventDefault();
        const username = document.getElementById('sign-in-email').value;
        const password = document.getElementById('password').value;
        console.debug(`username: <${username}> // password: <${password}>`)
        amplifySignIn(username, password).then(res => {
            console.debug(res)
            if (res.isSignedIn) {
                handleSignInModal(e);

            } else {
                window.alert(`The username or password you entered was incorrect.\n\nPlease try again.`);

            }
        }).catch(error => {
            console.debug(`SignInModal.handleSignIn.amplifySignIn Error`)
            console.error(error);
            window.alert(`The username or password you entered was incorrect.\n\nPlease try again.`);

        });

    };

    const onClickNavigate = (event, link = "") => {
        event.preventDefault();
        window.location = link;
    };

    return (
        <div>
            <Modal isOpen={showSignIn} toggle={handleSignInModal} className={'sign-in-modal'} backdrop={true} keyboard={true}>
                <ModalHeader className={'sign-in-modal-header'}>
                    <span className={'modal-header-span'}>
                        <div>
                            <h3>Sign in to</h3>
                        </div>
                        <div>
                            <img
                                id={'sign-in-logo'}
                                className={'sign-in-logo'}
                                src={logo}
                                alt={'SimplyBooks logo'}
                            />
                        </div>
                    </span>
                </ModalHeader>
                <ModalBody>
                    <div className={'sign-in-form-div'}>
                        <Form onSubmit={e => handleSignIn(e)} className={'sign-in-form-main'}>
                            <FormGroup className={'sign-in-form-group'}>
                                <Label for={'sign-in-email'}>Username:</Label>
                                <Input type={'email'} required={true} placeholder={'email'} id={'sign-in-email'} />
                                <Label for={'password'}>Password:</Label>
                                <Input id={'password'} placeholder={'******'} type={'password'} required={true} />
                            </FormGroup>
                            <div>
                                <button
                                    type={'submit'}
                                    onMouseOver={e => (e.currentTarget.style.backgroundColor = '#1bacb5')}
                                    onMouseOut={e => (e.currentTarget.style.backgroundColor = '#1fc6d0')}
                                >
                                    Sign In
                                </button>
                            </div>
                        </Form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={'modal-footer-span'}>
                        <div>
                            <h5><a href={'/#/forgot-password'} onClick={e => toggleSignInAndNavigate(e, '/#/forgot-password')}>Forgot password</a></h5>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );

};

export default SignInModal;