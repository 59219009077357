/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React from "react";
import './page.scss';
import Footer from "../components/footer/footer";
import { Container, Row, Col } from 'reactstrap';
import { getPageLocation } from "../utils/getPageLocation";
import { Environment, apiStage } from "../utils/constants";

const Faq = (props) => {

    const ConnectionWizardUrl = () => {
        return (
            <div>
                {!props.isAuthenticated ?
                    <p></p> :
                    <p><strong><a style={{color: "blue"}} href={getPageLocation(Environment, apiStage, 'connect')}>Go To the Connection Wizard</a></strong></p>
                }
            </div>
        );
    };




    return (
        <div className={'page-splash'}>
            <Container fluid={true} style={{cursor: 'default'}}>
                <Row xs={1} className={'row-1'}>
                    <Col>
                        <div className={'splash-inner'}>
                            <header>
                                <h2><strong>Facts & Questions</strong></h2>
                            </header>
                            <div className={'page-body'}>
                                <div className={'faq-body'}>
                                    <div>
                                        <h2>What is SimplyBooks.io?</h2>
                                        <p>
                                            In a nutshell, SimplyBooks is an application that connects a users online banking transaction feed to a Google Sheet within their Google account.
                                            The application that you see here online is primarily for getting your account set up and linked together with Google and your Bank. Since
                                            SimplyBooks doesn't store any of your data that isn't necessary for managing access to your SimplyBooks account, your bookkeeping
                                            activities take place in the Google Sheet that is automatically created when you sign-in, navigate to "Connect Bank",
                                            and click the "Get started here" panel.
                                        </p>
                                        <p>
                                            If you are more familiar with Excel, and want to see how switching to a more modern cloud-based sheet manager can benefit you, you can learn more about Google Sheets <strong><a style={{color: "blue"}} target={'_blank'} rel={'noreferrer'} href={'https://www.google.com/sheets/about/'}>here</a></strong>
                                        </p>
                                        <ConnectionWizardUrl />
                                    </div>

                                    <div>
                                        <h2>What if I don't own a business?</h2>
                                        <p>
                                            While this certainly isn't financial advice, and you should definitely talk to a registered CPA or licensed financial advisor, even people
                                            that are employed as W2 employees can benefit from keeping a clean set of books. There can be considerable benefits to keeping a tidy,
                                            categorized record of your transactions as they appear in your bank history when it comes to owning property, using your vehicle for work-related
                                            purposes, or even just effective budgeting and expense tracking.
                                        </p>
                                        <p>
                                            Since transaction categories in SimplyBooks are determined by you and what you type into your Google Sheet, there is no need to adhere to
                                            a predetermined chart of accounts like other bookkeeping software.
                                        </p>
                                    </div>

                                    <div>
                                        <h2>Do I need to have a Google account to use SimplyBooks?</h2>
                                        <p>
                                            Since we rely on an authenticated connection to Google Sheets to provide your transaction data, a Google account is required
                                            for the system to work properly.
                                        </p>
                                    </div>

                                    <div>
                                        <h2>How does SimplyBooks access my bank?</h2>
                                        <p>
                                            Like many leading financial software platforms, SimplyBooks uses a secure, read-only connection through Plaid to access your bank transaction details.
                                        </p>
                                        <p>
                                            You can read more about Plaid here: <strong><a style={{color: "blue"}} href={'https://plaid.com/why-plaid/'}>Why Plaid?</a></strong>
                                        </p>
                                        <p>
                                            As well as their end-user privacy policy here: <strong><a style={{color: "blue"}} href={'https://plaid.com/legal/#consumers'}>Consumer Privacy</a></strong>
                                        </p>
                                    </div>

                                    <div>
                                        <h2>How often does my sheet update with new transactions?</h2>
                                        <p>
                                            We receive signals from your bank every 4-8 hours. For simplicity's sake, we ignore transactions that are currently pending and wait for them
                                            to be officially posted before adding them to your Google sheet.
                                        </p>
                                    </div>

                                    <div>
                                        <h2>What if I have more than one set of books to keep?</h2>
                                        <p>
                                            When you visit the "Connect to Bank" page, if you already have an existing connection then you will only see a drop-down menu that says
                                            "Company Sheet Selection". Simply select "Create New" from the available options and you will be prompted to type in a name for your new sheet.
                                            With this new sheet selected, you will now see an option to connect your bank account. Now, both your original connection and your new connection
                                            have two separate Google Sheets, and the transactions are kept completely separated.
                                        </p>
                                        <p>
                                            If this is your first connection, then follow the steps as they appear in the connection wizard and then follow the steps
                                            above to create an additional, separated sheet.
                                        </p>
                                    </div>

                                    <div>
                                        <h2>I just linked my account, and downloaded all of my transactions. Why aren't they categorized?</h2>
                                        <p>
                                            When you create a SimplyBooks account, you are assigned your very own learning algorithm that is specific to you and your transactions.
                                            If there are no previously categorized transactions in your sheet(s), then the algorithm doesn't have anything to work with yet. The SimplyBooks
                                            algorithm learns specifically how you categorize your transactions when you categorize your transactions within your Google Sheet over time, typically
                                            learning in 12-24 hour windows every day as changes are detected.
                                        </p>
                                        <p>
                                            If you start your SimplyBooks subscription in March, then 3 months of transactions would have to be manually categorized
                                            <i> (January, February & March)</i>. For the remainder of the year, your personal algorithm will auto-fill your category based on your
                                            previously chosen categories with minimal errors unless it has not seen a particular merchant or transaction before; in which
                                            case it would leave your category blank for that merchant until it has the chance to observe what you choose for it.
                                        </p>
                                        <p>
                                            <u><i>Typically, with a full year of categorized transactions, you will only find yourself manually categorizing a small handful throughout the following year(s).</i></u>
                                        </p>
                                        <p>
                                            If you have lists of merchant and/or transaction names and categories from your previous transaction history or bookkeeping platform, then your
                                            algorithm can be fast-forwarded on a case-by-case basis. Please reach out to support@simplybooks.io for more information on this topic.
                                        </p>
                                    </div>

                                    <div>
                                        <h2>What information does SimplyBooks use in it's algorithm?</h2>
                                        <p>
                                            When we train our AI to discern between transactions and their categories, we use information about the transaction that comes from
                                            your bank, like: the merchant name, the transaction title, & of course the category that you chose. There is no personally identifiable
                                            information that goes into our model training, and all machine learning algorithms that we use are developed in-house, and solely
                                            owned and operated by SimplyBooks.io internally. We do not share or disclose your information in order to provide this service.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );


};

export default Faq;