/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React from "react";
import '../../pages/page.scss';


const ImportDataInstructions = () => {
    return (
        <div className={'upload-data-instructions'}>
            <h4>File Formatting Instructions</h4>
            <p>* Use the instructions below to make sure the data your are importing is formatted correctly. </p>
            <div className={'explanation'}>
                <p>
                    To clarify, importing data is not a way to back-fill transactions into your sheet, it is actually a way for you to enhance the learning algorithm
                    that attempts to categorize your transactions when we get them from your bank.
                </p>
                <p>
                    When you first start your SimplyBooks account, or haven't categorized any transactions in your sheet yet, your algorithm is essentially blank. Since the
                    learning process happens through the act of you categorizing your bank transactions in your sheet, a data import from a previous platform can fast-forward
                    that learning process so that the system is not reliant on manual categorization to start auto-categorizing.
                </p>
                <h5>
                    Please refer to the following sheet example as an acceptable CSV format:
                </h5>
                <div className={'example-table'}>
                    <table
                        cellSpacing={5}
                        cellPadding={5}
                        border={0}
                        align={'left'}
                        bgcolor={'#ffffff'}
                        className={'example-table-contents'}
                    >
                        <thead>
                        <tr>
                            <th>name</th>
                            <th>category</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr key={0} bgcolor={'#999393'}>
                            <td>Merchant name 1</td>
                            <td>Cost of Goods sold</td>
                        </tr>
                        <tr key={2} bgcolor={'#c9c5c5'}>
                            <td>Merchant name 2</td>
                            <td>Equipment</td>
                        </tr>
                        <tr key={3} bgcolor={'#999393'}>
                            <td>Visa POS Debit xxx1234 Sbucks #1053</td>
                            <td>Restaurants</td>
                        </tr>
                        <tr key={4} bgcolor={'#c9c5c5'}>
                            <td>...etc</td>
                            <td>...etc</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <ul>
                    <li>Imported data must be organized with 2 primary columns: "name" & "category".</li>
                    <li>Other columns <span style={{textDecoration: 'underline', fontWeight: 'bold'}}>can be present, but will be ignored.</span></li>
                    <li>Ensure that you remove rows that are missing values for either of the two required columns.</li>
                    <li>The "name" field can contain either an actual merchant name, or a transaction description as shown on most bank statements.</li>
                    <li>As always, the category can be whatever you want it to be. SimplyBooks is not bound by chart of account restrictions, and the algorithm will use this value exactly as it is written when making its predictions.</li>
                </ul>
            </div>

        </div>
    );

};

export default ImportDataInstructions;