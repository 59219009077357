/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React from "react";
import './page.scss';
import Footer from "../components/footer/footer";
import { Container, Row, Col } from 'reactstrap';


const PartnerWithUs = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const Partner = () => {
        return (
            <div className={'partner-div'}>
                <div>
                    <h2>Are you an accountant or accounting firm?</h2>
                    <h3>
                        We love a good opportunity to make bookkeeping and accounting as hands-free as possible for you and your clients.
                    </h3>
                    <p>
                        Since SimplyBooks' approach is to provide automation directly within Google Sheets, it's never been easier to
                        leverage our ease-of-use and automation to enhance your business and reduce client-side friction. In most cases,
                        since Google Sheets can be shared with little effort <i>(i.e. From your customer that uses SimplyBooks.io to you)</i>,
                        complex integrations are almost never required.
                    </p>
                    <p>
                        If you have a more complex request surrounding how we can allow you to leverage SimplyBooks.io to automate your
                        customer workflows, please reach out to us at support@simplybooks.io
                    </p>
                </div>
                <br />
            </div>
        );
    };

    const Sales = () => {
        return (
            <div className={'sales-div'}>
                <div>
                    <h2>Are you an ambitious sales person or affiliate sales network?</h2>
                    <p>
                        We are a small development team, so we do not offer in-house sales positions, <strong><u>However</u></strong>, we do offer generous
                        commission on sales and referrals that are made with an existing agreement.
                    </p>
                    <p>
                        Send your request to support@simplybooks.io to find out more.
                    </p>
                </div>
            </div>
        );
    };

    return (
        <div className={'page-splash'}>
            <Container fluid={true} style={{cursor: 'default'}}>
                <Row xs={1} className={'row-1'}>
                    <Col>
                        <div className={'splash-inner'}>
                            <header>
                                <h2><strong>Partner with us</strong></h2>
                            </header>
                            <div className={'page-body'}>
                                <Partner />
                                <hr style={{width: '80%', marginRight: '10%', marginLeft: '10%', marginTop: '1%'}}></hr>
                                <Sales />
                            </div>
                            <Footer />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );

};

export default PartnerWithUs;