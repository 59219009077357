/*Copyright 2024, Blake Donahoo, All rights reserved.*/
import React, { useState } from "react";
import { basicAnnualCode, basicCode, oneTimeCode } from "../../utils/constants";
import { isEmpty } from "../../utils/objectTools";
import { Card, CardBody, CardTitle } from "reactstrap";
import './selection.scss';
import './selection-options/selection-options.scss';

const Selection = () => {
    const [selection, setSelection] = useState(0);
    if (isEmpty(window.sessionStorage.getItem('selection'))) {
        window.sessionStorage.setItem('selection', '0');
    }
    const onClickSelectMembership = (event, item) => {
        event.preventDefault();
        console.debug(`[SELECTION] [${typeof(item)}] ${item}`)
        window.sessionStorage.setItem('selection', `${item}`)
        setSelection(item);
    };

    const MembershipSelectionDisplay = () => {
        let selectionText = "Basic Monthly";
        if (selection === 1) {
            selectionText = "Basic Annual";
        } else if (selection === 3) {
            selectionText = "One Month Trial"
        }
        return (
            <div className={'current-selection-display'}>
                    <span style={{display: 'block'}}>
                        <h2>
                            Current Selection: {selectionText}
                        </h2>
                    </span>
                <br />
            </div>
        );
    };

    const SelectionOption = ({selected, optionCode, onClickSelect, label, ul, price}) => {
        const card_class = selected === optionCode? 'membership-selected' : 'membership-option';
        if (card_class === 'membership-option') {
            return (
                <Card
                    className={card_class}
                    onClick={e => onClickSelect(e, optionCode)}
                    onMouseOver={e => e.currentTarget.style.boxShadow = '0 0 5px 5px #0ecfcf'}
                    onMouseOut={e => e.currentTarget.style.boxShadow = '10px 10px 8px #888888'}
                >
                    <CardBody>
                        <CardTitle tag={'h3'}>
                            {label}
                        </CardTitle>
                        <CardBody className={'membership-option-body'}>
                            <p className={'price'}>{price}</p>
                            <p className={'includes'}><u>Includes:</u></p>
                            {ul}
                        </CardBody>
                    </CardBody>
                </Card>
            );

        } else {
            return (
                <Card className={card_class}>
                    <CardBody>
                        <CardTitle tag={'h3'}>
                            {label}
                        </CardTitle>
                        <CardBody className={'membership-option-body'}>
                            <p className={'price'}>{price}</p>
                            <p className={'includes'}><u>Includes:</u></p>
                            {ul}
                        </CardBody>
                    </CardBody>
                </Card>
            );
        }

    };

    // <SelectionOption
    //     selected={selection}
    //     optionCode={oneTimeCode}
    //     onClickSelect={onClickSelectMembership}
    //     label={'One Month Trial'}
    //     price={'$9.99 non-recurring'}
    //     ul={<ul className={'inclusions'}>
    //         <li>1 bank connection with as many separate accounts as you have available.</li>
    //         <li>Connect your bank and sync all of your transactions within a 2-year period to a spreadsheet.</li>
    //         <li>One Month, non-recurring membership</li>
    //     </ul>}
    // />

    return (
        <div className={'membership-options'}>
                <span>
                    <SelectionOption
                        selected={selection}
                        optionCode={basicCode}
                        onClickSelect={onClickSelectMembership}
                        label={'Basic Monthly'}
                        price={'$9.99/mo'}
                        ul={<ul className={'inclusions'}>
                            <li>2 bank connection with as many separate accounts as you have available. <u><i>(with the option to add more)</i></u></li>
                            <li>Automatic Google Sheet management.</li>
                            <li>Automatic Daily Transaction Sync.</li>
                            <li>Automatic back-dating of transactions to the beginning of the year and end-of-year new sheet creation.</li>
                            <li>Personalized transaction category algorithm to automate your bookkeeping.</li>
                        </ul>}
                    />
                    <SelectionOption
                        selected={selection}
                        optionCode={basicAnnualCode}
                        onClickSelect={onClickSelectMembership}
                        label={'Basic Annual'}
                        price={'$99.99/yr'}
                        ul={<ul className={'inclusions'}>
                            <li>2 bank connection with as many separate accounts as you have available. <u><i>(with the option to add more)</i></u></li>
                            <li>Automatic Google Sheet management.</li>
                            <li>Automatic Daily Transaction Sync.</li>
                            <li>Automatic back-dating of transactions to the beginning of the year and end-of-year new sheet creation.</li>
                            <li>Personalized transaction category algorithm to automate your bookkeeping.</li>
                            <li><strong>17% Annual Discount</strong></li>
                        </ul>}
                    />
                </span>
            <MembershipSelectionDisplay />

        </div>
    );
    // <h5>*** All plans are backed by a money-back guarantee if your bank happens to be unsupported by Plaid</h5>
};

export default Selection;